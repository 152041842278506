import React, { useState } from "react";
import Image from "next/image";
import { beautifyTimestamp } from "../../../utils/toLocaleString";
import { completePaymentModalShow } from "../../../redux/counterSlice";
import { useDispatch } from "react-redux";
import {
  addCompleteOrder,
  addCompleteOrderItem,
  updateOrderItemDeliveryStatus,
} from "../../../redux/ordersSlice";
import EmptyState from "../../empty-state";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

function Row(props) {
  const dispatch = useDispatch();
  const { row, number } = props;
  const [open, setOpen] = useState(false);
  const [trackingNumbers, setTrackingNumbers] = useState(
    row.orderItems.reduce((acc, item) => {
      acc[item.id] = item.trackingNumber || "";
      return acc;
    }, {})
  );

  const handleChange = (e, orderItemId) => {
    setTrackingNumbers({
      ...trackingNumbers,
      [orderItemId]: e.target.value,
    });
  };

  const handleShipItem = (order, orderItemId, orderItem) => {
    const trackingNumber = trackingNumbers[orderItemId];
    if (!trackingNumber) {
      alert("Please enter tracking number");
      return;
    }

    dispatch(
      updateOrderItemDeliveryStatus({
        orderId: order.id,
        orderItemId,
        status: "ToRecieve",
        trackingNumber,
        order,
        orderItem,
      })
    );
  };

  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
            sx={{ color: "white" }}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell align="left">{number}</TableCell>
        <TableCell component="th" scope="row">
          {row.firstName}
        </TableCell>
        <TableCell align="left">{row.lastName}</TableCell>
        <TableCell align="left" className="lowercase">
          {row.email}
        </TableCell>
        <TableCell align="left">{row.phone}</TableCell>
        <TableCell align="left">
          {row.payment_status || row.paymentMethod}
        </TableCell>
        <TableCell align="left">{row.deliveryMethod}</TableCell>
        <TableCell align="left">{beautifyTimestamp(row.createdAt)}</TableCell>
        <TableCell align="left">{row.province}</TableCell>
        <TableCell align="left">{row.city}</TableCell>
        <TableCell align="left">{row.address}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                Order Items
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>#</TableCell>
                    <TableCell sx={{ minWidth: "150px !important" }}>
                      item
                    </TableCell>
                    <TableCell>Quantity</TableCell>
                    <TableCell align="left">Price</TableCell>
                    <TableCell align="left">Note</TableCell>
                    <TableCell align="left">Outstanding Balance</TableCell>
                    <TableCell
                      size="small"
                      align="left"
                      sx={{ minWidth: "200px !important" }}
                    >
                      Tracking Number
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{ minWidth: "150px !important" }}
                    >
                      Action
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.orderItems.map((orderItem, index) => {
                    const {
                      id,
                      title,
                      isFullPayment,
                      pokemonId,
                      digitPrice,
                      preOrderPrice,
                      totalPrice,
                      quantity,
                    } = orderItem;
                    return (
                      <TableRow key={id}>
                        <TableCell>{index + 1}</TableCell>
                        <TableCell component="th" scope="row">
                          <div className="flex gap-2">
                            <div>
                              <a
                                href={`/product/${pokemonId}`}
                                className="text-accent text-m text-ellipsis text-wrap"
                              >
                                {title}
                              </a>
                            </div>
                          </div>
                        </TableCell>
                        <TableCell>{quantity}</TableCell>
                        <TableCell align="left">{totalPrice}</TableCell>
                        <TableCell align="left">
                          {isFullPayment ? "Full Payment" : "Partial Payment"}
                        </TableCell>
                        <TableCell align="left">
                          {isFullPayment
                            ? "N/A"
                            : (digitPrice - preOrderPrice) * quantity}
                        </TableCell>
                        <TableCell sx={{ width: "150px" }}>
                          <input
                            type="text"
                            name="trackingNumber"
                            id="trackingNumber"
                            value={trackingNumbers[id]}
                            onChange={(e) => handleChange(e, id)}
                            placeholder="Enter tracking number"
                            className="bg-gray-50 border border-gray-300 text-black text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500"
                          />
                        </TableCell>
                        <TableCell>
                          <button
                            className="text-accent font-display text-sm font-semibold"
                            onClick={() => handleShipItem(row, id, orderItem)}
                          >
                            Ship Item
                          </button>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

const OrderList = ({ orders }) => {
  const dispatch = useDispatch();

  return (
    <>
      <div class="relative overflow-x-auto">
        <TableContainer
          component={Paper}
          className="dark:bg-jacarta-700 border-b dark:bg-gray-800 dark:border-jacarta-600"
        >
          <Table aria-label="collapsible table" className="admin-oders-table">
            <TableHead>
              <TableRow className="dark:text-white">
                <TableCell />
                <TableCell>#</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Surname</TableCell>
                <TableCell size="small" align="left">
                  Email
                </TableCell>
                <TableCell align="left">Phone</TableCell>
                <TableCell align="left">Payment Status</TableCell>
                <TableCell align="left">Delivery Method</TableCell>
                <TableCell align="left">Order Date</TableCell>
                <TableCell align="left">Province</TableCell>
                <TableCell align="left">City</TableCell>
                <TableCell align="left">Address</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {orders.map((row, index) => (
                <Row key={row.id} row={row} number={index + 1} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </>
  );
};

export default OrderList;
