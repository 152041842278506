import React from "react";
import { NewseLatter, Tranding_category } from "../../components/component";
import Meta from "../../components/Meta";
import Hero_4 from "../../components/hero/hero_4";
import CoverflowCarousel from "../../components/carousel/coverflowCarousel";

const Home_4 = () => {
  return (
    <>
      <Meta title="Ixcitement Store - Premium Collectibles" />
      <Hero_4 />
      <CoverflowCarousel />
      <Tranding_category />
      <NewseLatter bgWhite={true} />
    </>
  );
};

export default Home_4;
