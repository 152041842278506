const fakeData = [
  {
    name: "Pokémon Japanese Paradise Dragona Booster Box sv7a",
    description:
      "Pokémon Japanese Paradise Dragona Booster Box sv7a sealed\n\nThis item will take 7-14 business working days for delivery ",
    price: 1299,
    pictures: [
      {
        metadata: {
          tags: [],
          concepts: [],
        },
        sys: {
          space: {
            sys: {
              type: "Link",
              linkType: "Space",
              id: "x7xzeldz66so",
            },
          },
          id: "6jjpcY2xQwoAqodr0cblaX",
          type: "Asset",
          createdAt: "2024-11-23T01:11:47.286Z",
          updatedAt: "2024-11-23T01:11:47.286Z",
          environment: {
            sys: {
              id: "master",
              type: "Link",
              linkType: "Environment",
            },
          },
          publishedVersion: 4,
          revision: 1,
          locale: "en-US",
        },
        fields: {
          title: "sv7a",
          description: "",
          file: {
            url: "//images.ctfassets.net/x7xzeldz66so/6jjpcY2xQwoAqodr0cblaX/8133ca67406a5215c1412d524cf8a97b/IMG-6784.jpg",
            details: {
              size: 77152,
              image: {
                width: 554,
                height: 554,
              },
            },
            fileName: "IMG-6784.jpg",
            contentType: "image/jpeg",
          },
        },
      },
    ],
    preOrderPrice: 700,
    category: "Pokemon Cards",
    pudoFee: 60,
    deliveryGuyFee: 120,
    isCard: true,
    productQuantity: 0,
    space: {
      sys: {
        type: "Link",
        linkType: "Space",
        id: "x7xzeldz66so",
      },
    },
    id: "63gdE4PXvyi17JcZd9iORB",
    type: "Entry",
    createdAt: "2024-11-23T01:12:07.497Z",
    updatedAt: "2024-12-20T23:42:57.771Z",
    environment: {
      sys: {
        id: "master",
        type: "Link",
        linkType: "Environment",
      },
    },
    publishedVersion: 18,
    revision: 5,
    contentType: {
      sys: {
        type: "Link",
        linkType: "ContentType",
        id: "pokemon",
      },
    },
    locale: "en-US",
    imageUrl: [
      "https://images.ctfassets.net/x7xzeldz66so/6jjpcY2xQwoAqodr0cblaX/8133ca67406a5215c1412d524cf8a97b/IMG-6784.jpg",
    ],
  },
  {
    name: "Pokémon High Class Pack VMAX CLIMAX Japanese Booster BOX s8b",
    description:
      "New Pokemon Card High Class Pack VMAX CLIMAX Booster BOX Sealed s8b \n\nThis item will take 7-14 working days for delivery .",
    price: 2899,
    pictures: [
      {
        metadata: {
          tags: [],
          concepts: [],
        },
        sys: {
          space: {
            sys: {
              type: "Link",
              linkType: "Space",
              id: "x7xzeldz66so",
            },
          },
          id: "12RGz8wBdq3pUAxz1DEg89",
          type: "Asset",
          createdAt: "2024-11-23T01:06:44.429Z",
          updatedAt: "2024-11-23T01:06:44.429Z",
          environment: {
            sys: {
              id: "master",
              type: "Link",
              linkType: "Environment",
            },
          },
          publishedVersion: 5,
          revision: 1,
          locale: "en-US",
        },
        fields: {
          title: "Pokemon s8b",
          description: "",
          file: {
            url: "//images.ctfassets.net/x7xzeldz66so/12RGz8wBdq3pUAxz1DEg89/d6e190bb47b0d941d71c63332e44e983/Screenshot_20241122_202318_Gallery.jpg",
            details: {
              size: 1168099,
              image: {
                width: 1080,
                height: 2400,
              },
            },
            fileName: "Screenshot_20241122_202318_Gallery.jpg",
            contentType: "image/jpeg",
          },
        },
      },
    ],
    preOrderPrice: 1450,
    category: "Pokemon Cards",
    pudoFee: 60,
    deliveryGuyFee: 120,
    isCard: true,
    productQuantity: 5,
    space: {
      sys: {
        type: "Link",
        linkType: "Space",
        id: "x7xzeldz66so",
      },
    },
    id: "5gI927lZxXLGCuGVQTQit7",
    type: "Entry",
    createdAt: "2024-11-22T18:50:54.273Z",
    updatedAt: "2024-12-20T23:38:53.775Z",
    environment: {
      sys: {
        id: "master",
        type: "Link",
        linkType: "Environment",
      },
    },
    publishedVersion: 24,
    revision: 4,
    contentType: {
      sys: {
        type: "Link",
        linkType: "ContentType",
        id: "pokemon",
      },
    },
    locale: "en-US",
    imageUrl: [
      "https://images.ctfassets.net/x7xzeldz66so/12RGz8wBdq3pUAxz1DEg89/d6e190bb47b0d941d71c63332e44e983/Screenshot_20241122_202318_Gallery.jpg",
    ],
  },
  {
    name: "Pokémon Japanese VSTAR Universe (S12a) Booster Box",
    description:
      "Pokémon Japanese VSTAR Universe (S12a) Booster Box sealed .\n\nThis item will take 7-14 business working days for delivery. ",
    price: 2499,
    pictures: [
      {
        metadata: {
          tags: [],
          concepts: [],
        },
        sys: {
          space: {
            sys: {
              type: "Link",
              linkType: "Space",
              id: "x7xzeldz66so",
            },
          },
          id: "6twzr9j3LShW1V2uwteK8o",
          type: "Asset",
          createdAt: "2024-11-23T01:07:04.650Z",
          updatedAt: "2024-11-23T01:07:04.650Z",
          environment: {
            sys: {
              id: "master",
              type: "Link",
              linkType: "Environment",
            },
          },
          publishedVersion: 5,
          revision: 1,
          locale: "en-US",
        },
        fields: {
          title: "s12a",
          description: "",
          file: {
            url: "//images.ctfassets.net/x7xzeldz66so/6twzr9j3LShW1V2uwteK8o/f29e7c9f597239faf1449d41e801e737/tb_image_share_1732314163203.jpg.png",
            details: {
              size: 1060527,
              image: {
                width: 800,
                height: 800,
              },
            },
            fileName: "tb_image_share_1732314163203.jpg.png",
            contentType: "image/png",
          },
        },
      },
    ],
    preOrderPrice: 1250,
    category: "Pokemon Cards",
    pudoFee: 60,
    deliveryGuyFee: 120,
    isCard: true,
    space: {
      sys: {
        type: "Link",
        linkType: "Space",
        id: "x7xzeldz66so",
      },
    },
    id: "24Pjip2bIxvAybYMZd5PXu",
    type: "Entry",
    createdAt: "2024-11-23T00:47:23.013Z",
    updatedAt: "2024-12-16T07:55:10.957Z",
    environment: {
      sys: {
        id: "master",
        type: "Link",
        linkType: "Environment",
      },
    },
    publishedVersion: 21,
    revision: 4,
    contentType: {
      sys: {
        type: "Link",
        linkType: "ContentType",
        id: "pokemon",
      },
    },
    locale: "en-US",
    imageUrl: [
      "https://images.ctfassets.net/x7xzeldz66so/6twzr9j3LShW1V2uwteK8o/f29e7c9f597239faf1449d41e801e737/tb_image_share_1732314163203.jpg.png",
    ],
  },
  {
    name: "Pokémon Japanese Shiny Treasure ex (SV4a) High Class Booster Box",
    description:
      "Pokémon Japanese Shiny Treasure ex (SV4a) High Class Booster Box sealed\n\nThis item will take 7-14 business working days for delivery",
    price: 1299,
    pictures: [
      {
        metadata: {
          tags: [],
          concepts: [],
        },
        sys: {
          space: {
            sys: {
              type: "Link",
              linkType: "Space",
              id: "x7xzeldz66so",
            },
          },
          id: "2UONtVij1DYlypVxy73r6I",
          type: "Asset",
          createdAt: "2024-11-23T01:04:56.886Z",
          updatedAt: "2024-11-23T01:04:56.886Z",
          environment: {
            sys: {
              id: "master",
              type: "Link",
              linkType: "Environment",
            },
          },
          publishedVersion: 5,
          revision: 1,
          locale: "en-US",
        },
        fields: {
          title: "sv4a",
          description: "",
          file: {
            url: "//images.ctfassets.net/x7xzeldz66so/2UONtVij1DYlypVxy73r6I/1e401789eba09f40611d8eb1e765ec0e/tb_image_share_1732322998001.jpg.png",
            details: {
              size: 415590,
              image: {
                width: 1200,
                height: 996,
              },
            },
            fileName: "tb_image_share_1732322998001.jpg.png",
            contentType: "image/png",
          },
        },
      },
    ],
    preOrderPrice: 700,
    category: "Pokemon Cards",
    pudoFee: 60,
    deliveryGuyFee: 120,
    isCard: true,
    space: {
      sys: {
        type: "Link",
        linkType: "Space",
        id: "x7xzeldz66so",
      },
    },
    id: "4qbhAgWXXz2jXjFKRdVcDA",
    type: "Entry",
    createdAt: "2024-11-23T00:54:25.454Z",
    updatedAt: "2024-12-16T07:54:44.285Z",
    environment: {
      sys: {
        id: "master",
        type: "Link",
        linkType: "Environment",
      },
    },
    publishedVersion: 24,
    revision: 4,
    contentType: {
      sys: {
        type: "Link",
        linkType: "ContentType",
        id: "pokemon",
      },
    },
    locale: "en-US",
    imageUrl: [
      "https://images.ctfassets.net/x7xzeldz66so/2UONtVij1DYlypVxy73r6I/1e401789eba09f40611d8eb1e765ec0e/tb_image_share_1732322998001.jpg.png",
    ],
  },
  {
    name: "Pokémon Japanese Supercharged Breaker Booster Box SV8",
    description:
      "Pokémon Japanese Supercharged Breaker Booster Box SV8 sealed \n\nThis item will take 7-14 business working days for delivery .",
    price: 1299,
    pictures: [
      {
        metadata: {
          tags: [],
          concepts: [],
        },
        sys: {
          space: {
            sys: {
              type: "Link",
              linkType: "Space",
              id: "x7xzeldz66so",
            },
          },
          id: "2L585ZbTT8BPCqL18Gugui",
          type: "Asset",
          createdAt: "2024-11-23T01:07:20.932Z",
          updatedAt: "2024-11-23T01:07:20.932Z",
          environment: {
            sys: {
              id: "master",
              type: "Link",
              linkType: "Environment",
            },
          },
          publishedVersion: 4,
          revision: 1,
          locale: "en-US",
        },
        fields: {
          title: "sv8",
          description: "",
          file: {
            url: "//images.ctfassets.net/x7xzeldz66so/2L585ZbTT8BPCqL18Gugui/7c1ab8f097b51e7acee348a1e18322b9/IMG-7153.png",
            details: {
              size: 1474911,
              image: {
                width: 1080,
                height: 1080,
              },
            },
            fileName: "IMG-7153.png",
            contentType: "image/png",
          },
        },
      },
    ],
    preOrderPrice: 700,
    category: "Pokemon Cards",
    pudoFee: 60,
    deliveryGuyFee: 120,
    isCard: true,
    space: {
      sys: {
        type: "Link",
        linkType: "Space",
        id: "x7xzeldz66so",
      },
    },
    id: "LCvkoIgdLF4x6W6TuCWAB",
    type: "Entry",
    createdAt: "2024-11-23T01:02:52.941Z",
    updatedAt: "2024-12-16T07:51:01.784Z",
    environment: {
      sys: {
        id: "master",
        type: "Link",
        linkType: "Environment",
      },
    },
    publishedVersion: 17,
    revision: 3,
    contentType: {
      sys: {
        type: "Link",
        linkType: "ContentType",
        id: "pokemon",
      },
    },
    locale: "en-US",
    imageUrl: [
      "https://images.ctfassets.net/x7xzeldz66so/2L585ZbTT8BPCqL18Gugui/7c1ab8f097b51e7acee348a1e18322b9/IMG-7153.png",
    ],
  },
  {
    name: "Pokémon Japanese 151 sv2a Booster Box",
    description:
      "Pokemon 151 sv2a Japanese Booster Box . New and sealed.\n\nDelivery will take 7-14 working days .",
    price: 2499,
    pictures: [
      {
        metadata: {
          tags: [],
          concepts: [],
        },
        sys: {
          space: {
            sys: {
              type: "Link",
              linkType: "Space",
              id: "x7xzeldz66so",
            },
          },
          id: "6HtxoZLmFZHgmv3w1KIo1H",
          type: "Asset",
          createdAt: "2024-11-22T12:57:10.512Z",
          updatedAt: "2024-11-22T12:57:10.512Z",
          environment: {
            sys: {
              id: "master",
              type: "Link",
              linkType: "Environment",
            },
          },
          publishedVersion: 4,
          revision: 1,
          locale: "en-US",
        },
        fields: {
          title: "Pokemon 151",
          description: "",
          file: {
            url: "//images.ctfassets.net/x7xzeldz66so/6HtxoZLmFZHgmv3w1KIo1H/0fca2e3674bb4bf697d2f3a6bb222f3a/images__4_.jpeg",
            details: {
              size: 47537,
              image: {
                width: 373,
                height: 520,
              },
            },
            fileName: "images (4).jpeg",
            contentType: "image/jpeg",
          },
        },
      },
      {
        metadata: {
          tags: [],
          concepts: [],
        },
        sys: {
          space: {
            sys: {
              type: "Link",
              linkType: "Space",
              id: "x7xzeldz66so",
            },
          },
          id: "3jvqktYGw2zY9y0uBa5SSb",
          type: "Asset",
          createdAt: "2024-11-22T12:57:10.508Z",
          updatedAt: "2024-11-22T12:57:10.508Z",
          environment: {
            sys: {
              id: "master",
              type: "Link",
              linkType: "Environment",
            },
          },
          publishedVersion: 4,
          revision: 1,
          locale: "en-US",
        },
        fields: {
          title: "Pokemon 151",
          description: "",
          file: {
            url: "//images.ctfassets.net/x7xzeldz66so/3jvqktYGw2zY9y0uBa5SSb/bd93a5025c6cb7198cc16e5ceaeda662/Screenshot_20241122_085200_Gallery.jpg",
            details: {
              size: 150180,
              image: {
                width: 977,
                height: 702,
              },
            },
            fileName: "Screenshot_20241122_085200_Gallery.jpg",
            contentType: "image/jpeg",
          },
        },
      },
      {
        metadata: {
          tags: [],
          concepts: [],
        },
        sys: {
          space: {
            sys: {
              type: "Link",
              linkType: "Space",
              id: "x7xzeldz66so",
            },
          },
          id: "56hszDyBXcKFdpHShkiaFf",
          type: "Asset",
          createdAt: "2024-11-22T12:57:10.505Z",
          updatedAt: "2024-11-22T12:57:10.505Z",
          environment: {
            sys: {
              id: "master",
              type: "Link",
              linkType: "Environment",
            },
          },
          publishedVersion: 5,
          revision: 1,
          locale: "en-US",
        },
        fields: {
          title: "Pokemon 151 reveal",
          description: "",
          file: {
            url: "//images.ctfassets.net/x7xzeldz66so/56hszDyBXcKFdpHShkiaFf/e554616dddc523b9bfaa11c38f187f7d/Screenshot_20241122_085759_Gallery.jpg",
            details: {
              size: 498418,
              image: {
                width: 1080,
                height: 1727,
              },
            },
            fileName: "Screenshot_20241122_085759_Gallery.jpg",
            contentType: "image/jpeg",
          },
        },
      },
    ],
    preOrderPrice: 1250,
    category: "Pokemon Cards",
    pudoFee: 60,
    deliveryGuyFee: 120,
    isCard: true,
    space: {
      sys: {
        type: "Link",
        linkType: "Space",
        id: "x7xzeldz66so",
      },
    },
    id: "3BDtqQCOKBLmrrc9vHcL8o",
    type: "Entry",
    createdAt: "2024-11-22T07:05:06.694Z",
    updatedAt: "2024-12-16T07:49:58.495Z",
    environment: {
      sys: {
        id: "master",
        type: "Link",
        linkType: "Environment",
      },
    },
    publishedVersion: 28,
    revision: 5,
    contentType: {
      sys: {
        type: "Link",
        linkType: "ContentType",
        id: "pokemon",
      },
    },
    locale: "en-US",
    imageUrl: [
      "https://images.ctfassets.net/x7xzeldz66so/6HtxoZLmFZHgmv3w1KIo1H/0fca2e3674bb4bf697d2f3a6bb222f3a/images__4_.jpeg",
      "https://images.ctfassets.net/x7xzeldz66so/3jvqktYGw2zY9y0uBa5SSb/bd93a5025c6cb7198cc16e5ceaeda662/Screenshot_20241122_085200_Gallery.jpg",
      "https://images.ctfassets.net/x7xzeldz66so/56hszDyBXcKFdpHShkiaFf/e554616dddc523b9bfaa11c38f187f7d/Screenshot_20241122_085759_Gallery.jpg",
    ],
  },
  {
    name: "Pokémon Japanese Terastal Festival sv8a Booster Box",
    description:
      "Pokémon Terastal Festival ex is a Japanese High Class set of Pokémon cards The set will feature Eevee and all eight of its Eeveelutions as Pokémon ex, and may also include Special Illustration Rares of all the Eeveelutions.\n\nthis item is on pre-order and delivery will take 7-14 business working days after the release date .\n",
    price: 1999,
    pictures: [
      {
        metadata: {
          tags: [],
          concepts: [],
        },
        sys: {
          space: {
            sys: {
              type: "Link",
              linkType: "Space",
              id: "x7xzeldz66so",
            },
          },
          id: "1JlcDafo7gh9Nij1rV1G0Q",
          type: "Asset",
          createdAt: "2024-11-23T01:05:34.225Z",
          updatedAt: "2024-11-23T01:05:34.225Z",
          environment: {
            sys: {
              id: "master",
              type: "Link",
              linkType: "Environment",
            },
          },
          publishedVersion: 5,
          revision: 1,
          locale: "en-US",
        },
        fields: {
          title: "Eevee sv8a",
          description: "",
          file: {
            url: "//images.ctfassets.net/x7xzeldz66so/1JlcDafo7gh9Nij1rV1G0Q/88dca021cb2a080c7217cd5d94986010/tb_image_share_1732285988134.jpg.png",
            details: {
              size: 179425,
              image: {
                width: 500,
                height: 500,
              },
            },
            fileName: "tb_image_share_1732285988134.jpg.png",
            contentType: "image/png",
          },
        },
      },
      {
        metadata: {
          tags: [],
          concepts: [],
        },
        sys: {
          space: {
            sys: {
              type: "Link",
              linkType: "Space",
              id: "x7xzeldz66so",
            },
          },
          id: "1T20Y44dhwyQbE0CKJojAn",
          type: "Asset",
          createdAt: "2024-11-23T01:05:54.860Z",
          updatedAt: "2024-11-23T01:05:54.860Z",
          environment: {
            sys: {
              id: "master",
              type: "Link",
              linkType: "Environment",
            },
          },
          publishedVersion: 5,
          revision: 1,
          locale: "en-US",
        },
        fields: {
          title: "Eevee sv8a ex cards",
          description: "",
          file: {
            url: "//images.ctfassets.net/x7xzeldz66so/1T20Y44dhwyQbE0CKJojAn/0d4f9339a0f4ff0d213e62940ea6a8ba/tb_image_share_1732285978108.jpg.png",
            details: {
              size: 738733,
              image: {
                width: 810,
                height: 810,
              },
            },
            fileName: "tb_image_share_1732285978108.jpg.png",
            contentType: "image/png",
          },
        },
      },
      {
        metadata: {
          tags: [],
          concepts: [],
        },
        sys: {
          space: {
            sys: {
              type: "Link",
              linkType: "Space",
              id: "x7xzeldz66so",
            },
          },
          id: "5UPO7WJsQHKw7GgcjkMIUC",
          type: "Asset",
          createdAt: "2024-11-23T01:06:08.628Z",
          updatedAt: "2024-11-23T01:06:08.628Z",
          environment: {
            sys: {
              id: "master",
              type: "Link",
              linkType: "Environment",
            },
          },
          publishedVersion: 5,
          revision: 1,
          locale: "en-US",
        },
        fields: {
          title: "Eevee sv8a example",
          description: "",
          file: {
            url: "//images.ctfassets.net/x7xzeldz66so/5UPO7WJsQHKw7GgcjkMIUC/c7a3649f3d2f1c08e99e13fd433873b7/tb_image_share_1732285982785.jpg.png",
            details: {
              size: 262301,
              image: {
                width: 418,
                height: 418,
              },
            },
            fileName: "tb_image_share_1732285982785.jpg.png",
            contentType: "image/png",
          },
        },
      },
    ],
    preOrderPrice: 1300,
    category: "Pokemon Cards",
    pudoFee: 60,
    deliveryGuyFee: 120,
    isCard: true,
    space: {
      sys: {
        type: "Link",
        linkType: "Space",
        id: "x7xzeldz66so",
      },
    },
    id: "72HVYr9Uk11AOFTJ0eNyTx",
    type: "Entry",
    createdAt: "2024-11-22T14:37:29.167Z",
    updatedAt: "2024-12-16T07:42:56.130Z",
    environment: {
      sys: {
        id: "master",
        type: "Link",
        linkType: "Environment",
      },
    },
    publishedVersion: 33,
    revision: 4,
    contentType: {
      sys: {
        type: "Link",
        linkType: "ContentType",
        id: "pokemon",
      },
    },
    locale: "en-US",
    imageUrl: [
      "https://images.ctfassets.net/x7xzeldz66so/1JlcDafo7gh9Nij1rV1G0Q/88dca021cb2a080c7217cd5d94986010/tb_image_share_1732285988134.jpg.png",
      "https://images.ctfassets.net/x7xzeldz66so/1T20Y44dhwyQbE0CKJojAn/0d4f9339a0f4ff0d213e62940ea6a8ba/tb_image_share_1732285978108.jpg.png",
      "https://images.ctfassets.net/x7xzeldz66so/5UPO7WJsQHKw7GgcjkMIUC/c7a3649f3d2f1c08e99e13fd433873b7/tb_image_share_1732285982785.jpg.png",
    ],
  },
  {
    name: "Pokémon Volcano fire type statue figurine",
    description:
      "Pokémon Volcano fire type statue figurine 32cm . Made from pvc High quality . Figurines are hand painted nearly close to perfection . This is not a 3D print !\n\nPlease note due to slow shipping custom clearance , This item will take approximately 2 to 3 months to arrive upon order , please be patient for delivery \n",
    price: 2999,
    pictures: [
      {
        metadata: {
          tags: [],
          concepts: [],
        },
        sys: {
          space: {
            sys: {
              type: "Link",
              linkType: "Space",
              id: "x7xzeldz66so",
            },
          },
          id: "7cVds7bn0KpSTRJj7BV9H8",
          type: "Asset",
          createdAt: "2024-11-23T01:30:48.975Z",
          updatedAt: "2024-11-23T01:30:48.975Z",
          environment: {
            sys: {
              id: "master",
              type: "Link",
              linkType: "Environment",
            },
          },
          publishedVersion: 4,
          revision: 1,
          locale: "en-US",
        },
        fields: {
          title: "Pokémon Volcano ",
          description: "",
          file: {
            url: "//images.ctfassets.net/x7xzeldz66so/7cVds7bn0KpSTRJj7BV9H8/b8d3c59d29d91637b16ebec20923c4f7/tb_image_share_1732324987266.jpg.png",
            details: {
              size: 873498,
              image: {
                width: 800,
                height: 800,
              },
            },
            fileName: "tb_image_share_1732324987266.jpg.png",
            contentType: "image/png",
          },
        },
      },
      {
        metadata: {
          tags: [],
          concepts: [],
        },
        sys: {
          space: {
            sys: {
              type: "Link",
              linkType: "Space",
              id: "x7xzeldz66so",
            },
          },
          id: "2YaDeaLivoEsRWa751YMZS",
          type: "Asset",
          createdAt: "2024-11-23T01:32:04.691Z",
          updatedAt: "2024-11-23T01:32:04.691Z",
          environment: {
            sys: {
              id: "master",
              type: "Link",
              linkType: "Environment",
            },
          },
          publishedVersion: 4,
          revision: 1,
          locale: "en-US",
        },
        fields: {
          title: "Pokémon Volcano ",
          description: "",
          file: {
            url: "//images.ctfassets.net/x7xzeldz66so/2YaDeaLivoEsRWa751YMZS/5badb241a00aab15f5d46d599b63aaba/tb_image_share_1732325036998.jpg.png",
            details: {
              size: 557098,
              image: {
                width: 800,
                height: 800,
              },
            },
            fileName: "tb_image_share_1732325036998.jpg.png",
            contentType: "image/png",
          },
        },
      },
      {
        metadata: {
          tags: [],
          concepts: [],
        },
        sys: {
          space: {
            sys: {
              type: "Link",
              linkType: "Space",
              id: "x7xzeldz66so",
            },
          },
          id: "1brZI8Ap6qV1zqoHKAvNPS",
          type: "Asset",
          createdAt: "2024-11-23T01:32:42.102Z",
          updatedAt: "2024-11-23T01:32:42.102Z",
          environment: {
            sys: {
              id: "master",
              type: "Link",
              linkType: "Environment",
            },
          },
          publishedVersion: 4,
          revision: 1,
          locale: "en-US",
        },
        fields: {
          title: "Pokémon Volcano ",
          description: "",
          file: {
            url: "//images.ctfassets.net/x7xzeldz66so/1brZI8Ap6qV1zqoHKAvNPS/7cb53232236777e62d1f1bddbbe0e064/tb_image_share_1732325040786.jpg.png",
            details: {
              size: 438533,
              image: {
                width: 800,
                height: 800,
              },
            },
            fileName: "tb_image_share_1732325040786.jpg.png",
            contentType: "image/png",
          },
        },
      },
      {
        metadata: {
          tags: [],
          concepts: [],
        },
        sys: {
          space: {
            sys: {
              type: "Link",
              linkType: "Space",
              id: "x7xzeldz66so",
            },
          },
          id: "izfpzpkg2bvv6HImXihuH",
          type: "Asset",
          createdAt: "2024-11-23T01:33:28.180Z",
          updatedAt: "2024-11-23T01:33:28.180Z",
          environment: {
            sys: {
              id: "master",
              type: "Link",
              linkType: "Environment",
            },
          },
          publishedVersion: 4,
          revision: 1,
          locale: "en-US",
        },
        fields: {
          title: "Pokémon Volcano ",
          description: "",
          file: {
            url: "//images.ctfassets.net/x7xzeldz66so/izfpzpkg2bvv6HImXihuH/6a4d756ce42d886fd3a931ced7590b11/tb_image_share_1732325044174.jpg.png",
            details: {
              size: 476992,
              image: {
                width: 800,
                height: 800,
              },
            },
            fileName: "tb_image_share_1732325044174.jpg.png",
            contentType: "image/png",
          },
        },
      },
      {
        metadata: {
          tags: [],
          concepts: [],
        },
        sys: {
          space: {
            sys: {
              type: "Link",
              linkType: "Space",
              id: "x7xzeldz66so",
            },
          },
          id: "6kZHro8AtIDHWvQrxWkFYn",
          type: "Asset",
          createdAt: "2024-11-23T01:31:26.781Z",
          updatedAt: "2024-11-23T01:31:26.781Z",
          environment: {
            sys: {
              id: "master",
              type: "Link",
              linkType: "Environment",
            },
          },
          publishedVersion: 4,
          revision: 1,
          locale: "en-US",
        },
        fields: {
          title: "Pokémon Volcano ",
          description: "",
          file: {
            url: "//images.ctfassets.net/x7xzeldz66so/6kZHro8AtIDHWvQrxWkFYn/2f1725df151e55ccf70c352b752c68f1/tb_image_share_1732325033816.jpg.png",
            details: {
              size: 505087,
              image: {
                width: 800,
                height: 800,
              },
            },
            fileName: "tb_image_share_1732325033816.jpg.png",
            contentType: "image/png",
          },
        },
      },
      {
        metadata: {
          tags: [],
          concepts: [],
        },
        sys: {
          space: {
            sys: {
              type: "Link",
              linkType: "Space",
              id: "x7xzeldz66so",
            },
          },
          id: "7gG7WwL9b8Nuagpehl9v23",
          type: "Asset",
          createdAt: "2024-11-23T01:34:23.712Z",
          updatedAt: "2024-11-23T01:34:23.712Z",
          environment: {
            sys: {
              id: "master",
              type: "Link",
              linkType: "Environment",
            },
          },
          publishedVersion: 4,
          revision: 1,
          locale: "en-US",
        },
        fields: {
          title: "Pokémon Volcano ",
          description: "",
          file: {
            url: "//images.ctfassets.net/x7xzeldz66so/7gG7WwL9b8Nuagpehl9v23/cb7bcaa826ff82735ecc8a02a9bb3e83/tb_image_share_1732325049201.jpg.png",
            details: {
              size: 457535,
              image: {
                width: 800,
                height: 800,
              },
            },
            fileName: "tb_image_share_1732325049201.jpg.png",
            contentType: "image/png",
          },
        },
      },
    ],
    preOrderPrice: 1250,
    category: "Pokemon Figurines",
    pudoFee: 120,
    deliveryGuyFee: 120,
    isCard: false,
    space: {
      sys: {
        type: "Link",
        linkType: "Space",
        id: "x7xzeldz66so",
      },
    },
    id: "2KneTiWJj5Pdc3nJ7wHRiF",
    type: "Entry",
    createdAt: "2024-11-23T01:36:14.185Z",
    updatedAt: "2024-12-16T07:42:19.210Z",
    environment: {
      sys: {
        id: "master",
        type: "Link",
        linkType: "Environment",
      },
    },
    publishedVersion: 63,
    revision: 5,
    contentType: {
      sys: {
        type: "Link",
        linkType: "ContentType",
        id: "pokemon",
      },
    },
    locale: "en-US",
    imageUrl: [
      "https://images.ctfassets.net/x7xzeldz66so/7cVds7bn0KpSTRJj7BV9H8/b8d3c59d29d91637b16ebec20923c4f7/tb_image_share_1732324987266.jpg.png",
      "https://images.ctfassets.net/x7xzeldz66so/2YaDeaLivoEsRWa751YMZS/5badb241a00aab15f5d46d599b63aaba/tb_image_share_1732325036998.jpg.png",
      "https://images.ctfassets.net/x7xzeldz66so/1brZI8Ap6qV1zqoHKAvNPS/7cb53232236777e62d1f1bddbbe0e064/tb_image_share_1732325040786.jpg.png",
      "https://images.ctfassets.net/x7xzeldz66so/izfpzpkg2bvv6HImXihuH/6a4d756ce42d886fd3a931ced7590b11/tb_image_share_1732325044174.jpg.png",
      "https://images.ctfassets.net/x7xzeldz66so/6kZHro8AtIDHWvQrxWkFYn/2f1725df151e55ccf70c352b752c68f1/tb_image_share_1732325033816.jpg.png",
      "https://images.ctfassets.net/x7xzeldz66so/7gG7WwL9b8Nuagpehl9v23/cb7bcaa826ff82735ecc8a02a9bb3e83/tb_image_share_1732325049201.jpg.png",
    ],
  },
  {
    name: "Pokémon Undersea water type statue figurine",
    description:
      "Pokémon Undersea water type statue figurine 33cm . Made from pvc High quality . Figurines are hand painted nearly close to perfection . This is not a 3D print !\nPlease note due to slow shipping custom clearance , This item will take approximately 2 to 3 months to arrive upon order , please be patient for delivery ",
    price: 2999,
    pictures: [
      {
        metadata: {
          tags: [],
          concepts: [],
        },
        sys: {
          space: {
            sys: {
              type: "Link",
              linkType: "Space",
              id: "x7xzeldz66so",
            },
          },
          id: "4zHPirkSqFZhLeaAXy2fV2",
          type: "Asset",
          createdAt: "2024-11-23T01:45:50.981Z",
          updatedAt: "2024-11-23T01:45:50.981Z",
          environment: {
            sys: {
              id: "master",
              type: "Link",
              linkType: "Environment",
            },
          },
          publishedVersion: 7,
          revision: 1,
          locale: "en-US",
        },
        fields: {
          title: "Pokémon undersea",
          description: "",
          file: {
            url: "//images.ctfassets.net/x7xzeldz66so/4zHPirkSqFZhLeaAXy2fV2/cd78efbd7a009bd23e0009aef6d21c74/tb_image_share_1732326040428.jpg.png",
            details: {
              size: 930543,
              image: {
                width: 800,
                height: 800,
              },
            },
            fileName: "tb_image_share_1732326040428.jpg.png",
            contentType: "image/png",
          },
        },
      },
      {
        metadata: {
          tags: [],
          concepts: [],
        },
        sys: {
          space: {
            sys: {
              type: "Link",
              linkType: "Space",
              id: "x7xzeldz66so",
            },
          },
          id: "jiV8fsIys27qYXVyRQcdX",
          type: "Asset",
          createdAt: "2024-11-23T01:46:28.401Z",
          updatedAt: "2024-11-23T01:46:28.401Z",
          environment: {
            sys: {
              id: "master",
              type: "Link",
              linkType: "Environment",
            },
          },
          publishedVersion: 4,
          revision: 1,
          locale: "en-US",
        },
        fields: {
          title: "Pokémon undersea",
          description: "",
          file: {
            url: "//images.ctfassets.net/x7xzeldz66so/jiV8fsIys27qYXVyRQcdX/320fbcd1fa16b2a7c2f07b6ed37099a3/tb_image_share_1732326071622.jpg.png",
            details: {
              size: 469997,
              image: {
                width: 800,
                height: 800,
              },
            },
            fileName: "tb_image_share_1732326071622.jpg.png",
            contentType: "image/png",
          },
        },
      },
      {
        metadata: {
          tags: [],
          concepts: [],
        },
        sys: {
          space: {
            sys: {
              type: "Link",
              linkType: "Space",
              id: "x7xzeldz66so",
            },
          },
          id: "4C45vLhe0VDrDx6b4itm2q",
          type: "Asset",
          createdAt: "2024-11-23T01:47:03.658Z",
          updatedAt: "2024-11-23T01:47:03.658Z",
          environment: {
            sys: {
              id: "master",
              type: "Link",
              linkType: "Environment",
            },
          },
          publishedVersion: 4,
          revision: 1,
          locale: "en-US",
        },
        fields: {
          title: "Pokémon undersea",
          description: "",
          file: {
            url: "//images.ctfassets.net/x7xzeldz66so/4C45vLhe0VDrDx6b4itm2q/06b27f3dbb2f7829be4f6b97e4f98d4c/tb_image_share_1732326079379.jpg.png",
            details: {
              size: 447629,
              image: {
                width: 800,
                height: 800,
              },
            },
            fileName: "tb_image_share_1732326079379.jpg.png",
            contentType: "image/png",
          },
        },
      },
      {
        metadata: {
          tags: [],
          concepts: [],
        },
        sys: {
          space: {
            sys: {
              type: "Link",
              linkType: "Space",
              id: "x7xzeldz66so",
            },
          },
          id: "3OJtJ4oVqR6KxLwhrM8msi",
          type: "Asset",
          createdAt: "2024-11-23T01:47:44.901Z",
          updatedAt: "2024-11-23T01:47:44.901Z",
          environment: {
            sys: {
              id: "master",
              type: "Link",
              linkType: "Environment",
            },
          },
          publishedVersion: 4,
          revision: 1,
          locale: "en-US",
        },
        fields: {
          title: "Pokémon undersea",
          description: "",
          file: {
            url: "//images.ctfassets.net/x7xzeldz66so/3OJtJ4oVqR6KxLwhrM8msi/12f6671a66bbbedd78fe3cc8c446f9d4/tb_image_share_1732326084546.jpg.png",
            details: {
              size: 443759,
              image: {
                width: 800,
                height: 800,
              },
            },
            fileName: "tb_image_share_1732326084546.jpg.png",
            contentType: "image/png",
          },
        },
      },
      {
        metadata: {
          tags: [],
          concepts: [],
        },
        sys: {
          space: {
            sys: {
              type: "Link",
              linkType: "Space",
              id: "x7xzeldz66so",
            },
          },
          id: "30mWAY29aiswqWolpIRWWI",
          type: "Asset",
          createdAt: "2024-11-23T01:48:32.354Z",
          updatedAt: "2024-11-23T01:48:32.354Z",
          environment: {
            sys: {
              id: "master",
              type: "Link",
              linkType: "Environment",
            },
          },
          publishedVersion: 4,
          revision: 1,
          locale: "en-US",
        },
        fields: {
          title: "Pokémon undersea",
          description: "",
          file: {
            url: "//images.ctfassets.net/x7xzeldz66so/30mWAY29aiswqWolpIRWWI/779b31e80bc7cc60abe40cacea281691/tb_image_share_1732326094155.jpg.png",
            details: {
              size: 420706,
              image: {
                width: 800,
                height: 800,
              },
            },
            fileName: "tb_image_share_1732326094155.jpg.png",
            contentType: "image/png",
          },
        },
      },
    ],
    preOrderPrice: 1250,
    category: "Pokemon Figurines",
    pudoFee: 120,
    deliveryGuyFee: 120,
    isCard: false,
    space: {
      sys: {
        type: "Link",
        linkType: "Space",
        id: "x7xzeldz66so",
      },
    },
    id: "33hsAd4pnuoVh12RkCR4Jv",
    type: "Entry",
    createdAt: "2024-11-23T01:50:01.580Z",
    updatedAt: "2024-12-16T07:41:44.320Z",
    environment: {
      sys: {
        id: "master",
        type: "Link",
        linkType: "Environment",
      },
    },
    publishedVersion: 24,
    revision: 5,
    contentType: {
      sys: {
        type: "Link",
        linkType: "ContentType",
        id: "pokemon",
      },
    },
    locale: "en-US",
    imageUrl: [
      "https://images.ctfassets.net/x7xzeldz66so/4zHPirkSqFZhLeaAXy2fV2/cd78efbd7a009bd23e0009aef6d21c74/tb_image_share_1732326040428.jpg.png",
      "https://images.ctfassets.net/x7xzeldz66so/jiV8fsIys27qYXVyRQcdX/320fbcd1fa16b2a7c2f07b6ed37099a3/tb_image_share_1732326071622.jpg.png",
      "https://images.ctfassets.net/x7xzeldz66so/4C45vLhe0VDrDx6b4itm2q/06b27f3dbb2f7829be4f6b97e4f98d4c/tb_image_share_1732326079379.jpg.png",
      "https://images.ctfassets.net/x7xzeldz66so/3OJtJ4oVqR6KxLwhrM8msi/12f6671a66bbbedd78fe3cc8c446f9d4/tb_image_share_1732326084546.jpg.png",
      "https://images.ctfassets.net/x7xzeldz66so/30mWAY29aiswqWolpIRWWI/779b31e80bc7cc60abe40cacea281691/tb_image_share_1732326094155.jpg.png",
    ],
  },
  {
    name: "Pokémon Gengar ghost evolution statue figurine ",
    description:
      "Ghost Gengar evolution statue ,35cm . Made from pvc High quality . Figurines are hand painted nearly close to perfection . This is not a 3D print !\n\nShipping will take 2 to 3 months for delivery .",
    price: 2999,
    pictures: [
      {
        metadata: {
          tags: [],
          concepts: [],
        },
        sys: {
          space: {
            sys: {
              type: "Link",
              linkType: "Space",
              id: "x7xzeldz66so",
            },
          },
          id: "2dlPxCfA5J0WSPsZmCjMOf",
          type: "Asset",
          createdAt: "2024-11-28T07:45:21.547Z",
          updatedAt: "2024-11-28T07:45:21.547Z",
          environment: {
            sys: {
              id: "master",
              type: "Link",
              linkType: "Environment",
            },
          },
          publishedVersion: 4,
          revision: 1,
          locale: "en-US",
        },
        fields: {
          title: "Gengar",
          description: "",
          file: {
            url: "//images.ctfassets.net/x7xzeldz66so/2dlPxCfA5J0WSPsZmCjMOf/56aa6b88784885857f855741b8922c5f/tb_image_share_1732767338393.jpg.png",
            details: {
              size: 964997,
              image: {
                width: 1200,
                height: 1200,
              },
            },
            fileName: "tb_image_share_1732767338393.jpg.png",
            contentType: "image/png",
          },
        },
      },
      {
        metadata: {
          tags: [],
          concepts: [],
        },
        sys: {
          space: {
            sys: {
              type: "Link",
              linkType: "Space",
              id: "x7xzeldz66so",
            },
          },
          id: "4mIDSrKIzRYzbSen3fT7lL",
          type: "Asset",
          createdAt: "2024-11-28T07:44:37.011Z",
          updatedAt: "2024-11-28T07:44:37.011Z",
          environment: {
            sys: {
              id: "master",
              type: "Link",
              linkType: "Environment",
            },
          },
          publishedVersion: 4,
          revision: 1,
          locale: "en-US",
        },
        fields: {
          title: "Gengar",
          description: "",
          file: {
            url: "//images.ctfassets.net/x7xzeldz66so/4mIDSrKIzRYzbSen3fT7lL/d1374da2c1582927d137161f0157bab6/tb_image_share_1732767347370.jpg.png",
            details: {
              size: 769578,
              image: {
                width: 1200,
                height: 1200,
              },
            },
            fileName: "tb_image_share_1732767347370.jpg.png",
            contentType: "image/png",
          },
        },
      },
      {
        metadata: {
          tags: [],
          concepts: [],
        },
        sys: {
          space: {
            sys: {
              type: "Link",
              linkType: "Space",
              id: "x7xzeldz66so",
            },
          },
          id: "6QTJplY58bO8V4kTNR3rrr",
          type: "Asset",
          createdAt: "2024-11-28T07:46:29.090Z",
          updatedAt: "2024-11-28T07:46:29.090Z",
          environment: {
            sys: {
              id: "master",
              type: "Link",
              linkType: "Environment",
            },
          },
          publishedVersion: 3,
          revision: 1,
          locale: "en-US",
        },
        fields: {
          title: "tb image share 1732767352751.jpg",
          description: "",
          file: {
            url: "//images.ctfassets.net/x7xzeldz66so/6QTJplY58bO8V4kTNR3rrr/b382fabcf296111e3c3348890f5a38d5/tb_image_share_1732767352751.jpg.png",
            details: {
              size: 977396,
              image: {
                width: 1200,
                height: 1200,
              },
            },
            fileName: "tb_image_share_1732767352751.jpg.png",
            contentType: "image/png",
          },
        },
      },
      {
        metadata: {
          tags: [],
          concepts: [],
        },
        sys: {
          space: {
            sys: {
              type: "Link",
              linkType: "Space",
              id: "x7xzeldz66so",
            },
          },
          id: "7L7YmMkPxmvS2ki9FuVWfn",
          type: "Asset",
          createdAt: "2024-11-28T07:47:20.912Z",
          updatedAt: "2024-11-28T07:47:20.912Z",
          environment: {
            sys: {
              id: "master",
              type: "Link",
              linkType: "Environment",
            },
          },
          publishedVersion: 3,
          revision: 1,
          locale: "en-US",
        },
        fields: {
          title: "tb image share 1732767357186.jpg",
          description: "",
          file: {
            url: "//images.ctfassets.net/x7xzeldz66so/7L7YmMkPxmvS2ki9FuVWfn/cc28bf54609d7d1d515fa50444797389/tb_image_share_1732767357186.jpg.png",
            details: {
              size: 937718,
              image: {
                width: 1200,
                height: 1200,
              },
            },
            fileName: "tb_image_share_1732767357186.jpg.png",
            contentType: "image/png",
          },
        },
      },
    ],
    preOrderPrice: 1250,
    category: "Pokemon Figurines",
    pudoFee: 120,
    deliveryGuyFee: 120,
    isCard: false,
    space: {
      sys: {
        type: "Link",
        linkType: "Space",
        id: "x7xzeldz66so",
      },
    },
    id: "6Lo3WlzEl2ocXzS37BfKK9",
    type: "Entry",
    createdAt: "2024-11-28T07:47:48.164Z",
    updatedAt: "2024-12-16T07:39:44.432Z",
    environment: {
      sys: {
        id: "master",
        type: "Link",
        linkType: "Environment",
      },
    },
    publishedVersion: 32,
    revision: 3,
    contentType: {
      sys: {
        type: "Link",
        linkType: "ContentType",
        id: "pokemon",
      },
    },
    locale: "en-US",
    imageUrl: [
      "https://images.ctfassets.net/x7xzeldz66so/2dlPxCfA5J0WSPsZmCjMOf/56aa6b88784885857f855741b8922c5f/tb_image_share_1732767338393.jpg.png",
      "https://images.ctfassets.net/x7xzeldz66so/4mIDSrKIzRYzbSen3fT7lL/d1374da2c1582927d137161f0157bab6/tb_image_share_1732767347370.jpg.png",
      "https://images.ctfassets.net/x7xzeldz66so/6QTJplY58bO8V4kTNR3rrr/b382fabcf296111e3c3348890f5a38d5/tb_image_share_1732767352751.jpg.png",
      "https://images.ctfassets.net/x7xzeldz66so/7L7YmMkPxmvS2ki9FuVWfn/cc28bf54609d7d1d515fa50444797389/tb_image_share_1732767357186.jpg.png",
    ],
  },
  {
    name: "Pokémon Jungle grass type statue figurine",
    description:
      "Pokémon jungle grass type statue figurine 32cm . Made from pvc High quality . Figurines are hand painted nearly close to perfection . This is not a 3D print !\n\nPlease note due to slow shipping custom clearance , This item will take approximately 2 to 3 months to arrive upon order , please be patient for delivery \n",
    price: 2999,
    pictures: [
      {
        metadata: {
          tags: [],
          concepts: [],
        },
        sys: {
          space: {
            sys: {
              type: "Link",
              linkType: "Space",
              id: "x7xzeldz66so",
            },
          },
          id: "74o35gAsYddJ1lamGh1mJs",
          type: "Asset",
          createdAt: "2024-11-23T01:57:54.944Z",
          updatedAt: "2024-11-23T01:57:54.944Z",
          environment: {
            sys: {
              id: "master",
              type: "Link",
              linkType: "Environment",
            },
          },
          publishedVersion: 3,
          revision: 1,
          locale: "en-US",
        },
        fields: {
          title: "tb image share 1732326710979.jpg",
          description: "",
          file: {
            url: "//images.ctfassets.net/x7xzeldz66so/74o35gAsYddJ1lamGh1mJs/f75dfd74b95185e690fe18bbac01ca60/tb_image_share_1732326710979.jpg.png",
            details: {
              size: 1088117,
              image: {
                width: 800,
                height: 800,
              },
            },
            fileName: "tb_image_share_1732326710979.jpg.png",
            contentType: "image/png",
          },
        },
      },
      {
        metadata: {
          tags: [],
          concepts: [],
        },
        sys: {
          space: {
            sys: {
              type: "Link",
              linkType: "Space",
              id: "x7xzeldz66so",
            },
          },
          id: "5N4LtiRSaTGnAYULzU1jSw",
          type: "Asset",
          createdAt: "2024-11-23T01:58:33.273Z",
          updatedAt: "2024-11-23T01:58:33.273Z",
          environment: {
            sys: {
              id: "master",
              type: "Link",
              linkType: "Environment",
            },
          },
          publishedVersion: 3,
          revision: 1,
          locale: "en-US",
        },
        fields: {
          title: "tb image share 1732326919139.jpg",
          description: "",
          file: {
            url: "//images.ctfassets.net/x7xzeldz66so/5N4LtiRSaTGnAYULzU1jSw/5257a94048c629edd1f6465f1e851a1a/tb_image_share_1732326919139.jpg.png",
            details: {
              size: 701092,
              image: {
                width: 1200,
                height: 1198,
              },
            },
            fileName: "tb_image_share_1732326919139.jpg.png",
            contentType: "image/png",
          },
        },
      },
      {
        metadata: {
          tags: [],
          concepts: [],
        },
        sys: {
          space: {
            sys: {
              type: "Link",
              linkType: "Space",
              id: "x7xzeldz66so",
            },
          },
          id: "3TrDRRMWemZNguzfcXg3YV",
          type: "Asset",
          createdAt: "2024-11-23T01:59:07.853Z",
          updatedAt: "2024-11-23T01:59:07.853Z",
          environment: {
            sys: {
              id: "master",
              type: "Link",
              linkType: "Environment",
            },
          },
          publishedVersion: 3,
          revision: 1,
          locale: "en-US",
        },
        fields: {
          title: "tb image share 1732326928426.jpg",
          description: "",
          file: {
            url: "//images.ctfassets.net/x7xzeldz66so/3TrDRRMWemZNguzfcXg3YV/eaa9d106f2950aef0781c0e60a96b437/tb_image_share_1732326928426.jpg.png",
            details: {
              size: 759421,
              image: {
                width: 1200,
                height: 1200,
              },
            },
            fileName: "tb_image_share_1732326928426.jpg.png",
            contentType: "image/png",
          },
        },
      },
    ],
    preOrderPrice: 1250,
    category: "Pokemon Figurines",
    pudoFee: 120,
    deliveryGuyFee: 120,
    isCard: false,
    space: {
      sys: {
        type: "Link",
        linkType: "Space",
        id: "x7xzeldz66so",
      },
    },
    id: "3MMlQ9yzWZWJsGgOeV0fG5",
    type: "Entry",
    createdAt: "2024-11-23T01:59:18.171Z",
    updatedAt: "2024-12-16T07:39:20.591Z",
    environment: {
      sys: {
        id: "master",
        type: "Link",
        linkType: "Environment",
      },
    },
    publishedVersion: 17,
    revision: 4,
    contentType: {
      sys: {
        type: "Link",
        linkType: "ContentType",
        id: "pokemon",
      },
    },
    locale: "en-US",
    imageUrl: [
      "https://images.ctfassets.net/x7xzeldz66so/74o35gAsYddJ1lamGh1mJs/f75dfd74b95185e690fe18bbac01ca60/tb_image_share_1732326710979.jpg.png",
      "https://images.ctfassets.net/x7xzeldz66so/5N4LtiRSaTGnAYULzU1jSw/5257a94048c629edd1f6465f1e851a1a/tb_image_share_1732326919139.jpg.png",
      "https://images.ctfassets.net/x7xzeldz66so/3TrDRRMWemZNguzfcXg3YV/eaa9d106f2950aef0781c0e60a96b437/tb_image_share_1732326928426.jpg.png",
    ],
  },
  {
    name: "Dragon Ball Z Fighters Set Figurine",
    description:
      "High quality DBZ figurine , comes as a set with the bottom stand . 31cm height. \n\nPlease note that this item will take approximately 2 to 3 months to deliver .\n",
    price: 1799,
    pictures: [
      {
        metadata: {
          tags: [],
          concepts: [],
        },
        sys: {
          space: {
            sys: {
              type: "Link",
              linkType: "Space",
              id: "x7xzeldz66so",
            },
          },
          id: "6rnkQzdsKvFlcyBqTxPnr4",
          type: "Asset",
          createdAt: "2024-12-04T05:14:30.207Z",
          updatedAt: "2024-12-04T05:14:30.207Z",
          environment: {
            sys: {
              id: "master",
              type: "Link",
              linkType: "Environment",
            },
          },
          publishedVersion: 5,
          revision: 1,
          locale: "en-US",
        },
        fields: {
          title: "DBZ fighters",
          description: "",
          file: {
            url: "//images.ctfassets.net/x7xzeldz66so/6rnkQzdsKvFlcyBqTxPnr4/dee84826a9be4c9c42ff80865116435e/tb_image_share_1733279069868.jpg.png",
            details: {
              size: 2059104,
              image: {
                width: 1200,
                height: 1200,
              },
            },
            fileName: "tb_image_share_1733279069868.jpg.png",
            contentType: "image/png",
          },
        },
      },
      {
        metadata: {
          tags: [],
          concepts: [],
        },
        sys: {
          space: {
            sys: {
              type: "Link",
              linkType: "Space",
              id: "x7xzeldz66so",
            },
          },
          id: "3Xqpe9J9rg5FfoYUBWcUWd",
          type: "Asset",
          createdAt: "2024-12-04T05:15:11.797Z",
          updatedAt: "2024-12-04T05:15:11.797Z",
          environment: {
            sys: {
              id: "master",
              type: "Link",
              linkType: "Environment",
            },
          },
          publishedVersion: 4,
          revision: 1,
          locale: "en-US",
        },
        fields: {
          title: "DBZ fighters",
          description: "",
          file: {
            url: "//images.ctfassets.net/x7xzeldz66so/3Xqpe9J9rg5FfoYUBWcUWd/eeaa29555bc3bf0062f8bfabfa820b89/tb_image_share_1733279076157.jpg.png",
            details: {
              size: 1888582,
              image: {
                width: 900,
                height: 1200,
              },
            },
            fileName: "tb_image_share_1733279076157.jpg.png",
            contentType: "image/png",
          },
        },
      },
      {
        metadata: {
          tags: [],
          concepts: [],
        },
        sys: {
          space: {
            sys: {
              type: "Link",
              linkType: "Space",
              id: "x7xzeldz66so",
            },
          },
          id: "52GU5Fk4ItJCjGNAo1SYuV",
          type: "Asset",
          createdAt: "2024-12-04T05:15:49.769Z",
          updatedAt: "2024-12-04T05:15:49.769Z",
          environment: {
            sys: {
              id: "master",
              type: "Link",
              linkType: "Environment",
            },
          },
          publishedVersion: 4,
          revision: 1,
          locale: "en-US",
        },
        fields: {
          title: "DBZ fighters",
          description: "",
          file: {
            url: "//images.ctfassets.net/x7xzeldz66so/52GU5Fk4ItJCjGNAo1SYuV/831cfbbd444070b4e2b2fdc4b19b7245/tb_image_share_1733279082141.jpg.png",
            details: {
              size: 1931128,
              image: {
                width: 900,
                height: 1200,
              },
            },
            fileName: "tb_image_share_1733279082141.jpg.png",
            contentType: "image/png",
          },
        },
      },
      {
        metadata: {
          tags: [],
          concepts: [],
        },
        sys: {
          space: {
            sys: {
              type: "Link",
              linkType: "Space",
              id: "x7xzeldz66so",
            },
          },
          id: "4240RHDLM3wvjbC9T01F8x",
          type: "Asset",
          createdAt: "2024-12-04T05:16:24.709Z",
          updatedAt: "2024-12-04T05:16:24.709Z",
          environment: {
            sys: {
              id: "master",
              type: "Link",
              linkType: "Environment",
            },
          },
          publishedVersion: 4,
          revision: 1,
          locale: "en-US",
        },
        fields: {
          title: "DBZ fighters",
          description: "",
          file: {
            url: "//images.ctfassets.net/x7xzeldz66so/4240RHDLM3wvjbC9T01F8x/644c878d08146402a034bf35b8b31468/tb_image_share_1733279088033.jpg.png",
            details: {
              size: 1845602,
              image: {
                width: 900,
                height: 1200,
              },
            },
            fileName: "tb_image_share_1733279088033.jpg.png",
            contentType: "image/png",
          },
        },
      },
    ],
    preOrderPrice: 750,
    category: "Dragon Ball Z Figurines",
    pudoFee: 120,
    deliveryGuyFee: 120,
    isCard: false,
    space: {
      sys: {
        type: "Link",
        linkType: "Space",
        id: "x7xzeldz66so",
      },
    },
    id: "7tRFuiRTN8QpRhExVlTxX6",
    type: "Entry",
    createdAt: "2024-12-04T05:16:39.628Z",
    updatedAt: "2024-12-16T07:38:54.278Z",
    environment: {
      sys: {
        id: "master",
        type: "Link",
        linkType: "Environment",
      },
    },
    publishedVersion: 33,
    revision: 3,
    contentType: {
      sys: {
        type: "Link",
        linkType: "ContentType",
        id: "pokemon",
      },
    },
    locale: "en-US",
    imageUrl: [
      "https://images.ctfassets.net/x7xzeldz66so/6rnkQzdsKvFlcyBqTxPnr4/dee84826a9be4c9c42ff80865116435e/tb_image_share_1733279069868.jpg.png",
      "https://images.ctfassets.net/x7xzeldz66so/3Xqpe9J9rg5FfoYUBWcUWd/eeaa29555bc3bf0062f8bfabfa820b89/tb_image_share_1733279076157.jpg.png",
      "https://images.ctfassets.net/x7xzeldz66so/52GU5Fk4ItJCjGNAo1SYuV/831cfbbd444070b4e2b2fdc4b19b7245/tb_image_share_1733279082141.jpg.png",
      "https://images.ctfassets.net/x7xzeldz66so/4240RHDLM3wvjbC9T01F8x/644c878d08146402a034bf35b8b31468/tb_image_share_1733279088033.jpg.png",
    ],
  },
];

export { fakeData };
