import {
  createSlice,
  createAsyncThunk,
  createSelector,
} from "@reduxjs/toolkit";

// Async thunk for fetching all orders
export const fetchAllOrders = createAsyncThunk(
  "orders/fetchAllOrders",
  async (_, { rejectWithValue }) => {
    try {
      const response = await fetch(`/api/get-all-order`);
      if (!response.ok) {
        throw new Error("Failed to fetch all orders");
      }
      const data = await response.json();
      return data.orders; // Extract the 'orders' array from the response
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

// Async thunk for fetching user orders
export const fetchOrdersByUserId = createAsyncThunk(
  "orders/fetchOrdersByUserId",
  async (userId, { rejectWithValue }) => {
    try {
      const response = await fetch(`/api/get-order-by-user?userId=${userId}`);
      if (!response.ok) {
        throw new Error("Failed to fetch orders");
      }
      const data = await response.json();
      return data.orders; // Extract the 'orders' array from the response
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

// Async thunk for adding a new order
export const addOrder = createAsyncThunk(
  "orders/addOrder",
  async (order, { rejectWithValue }) => {
    try {
      const response = await fetch("/api/create-order", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(order),
      });

      if (!response.ok) {
        throw new Error("Failed to add order");
      }

      const data = await response.json();
      return data.order; // Assume the server returns the created order object
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

// Async thunk for updating order item status
export const updateOrderItemDeliveryStatus = createAsyncThunk(
  "orders/updateOrderItemDeliveryStatus",
  async (
    { orderId, orderItemId, status, trackingNumber, order, orderItem },
    { rejectWithValue }
  ) => {
    try {
      const response = await fetch("/api/update-order-item-status", {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          orderId,
          orderItemId,
          status,
          trackingNumber,
          order,
          orderItem,
        }),
      });

      if (!response.ok) {
        throw new Error("Failed to update order item status");
      }

      const data = await response.json();
      return data.orderItem; // Assume the server returns the updated order item object
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

// Orders slice
const ordersSlice = createSlice({
  name: "orders",
  initialState: {
    orders: [],
    allOrders: [],
    completeOrder: {},
    completeOrderItem: {},
    loading: false,
    error: null,
  },
  reducers: {
    addCompleteOrder: (state, action) => {
      state.completeOrder = action.payload;
    },
    addCompleteOrderItem: (state, action) => {
      state.completeOrderItem = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchOrdersByUserId.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchOrdersByUserId.fulfilled, (state, action) => {
        state.loading = false;
        state.orders = action.payload;
      })
      .addCase(fetchOrdersByUserId.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // Add Order
      .addCase(addOrder.pending, (state) => {
        state.addOrderStatus = "loading";
        state.error = null;
      })
      .addCase(addOrder.fulfilled, (state, action) => {
        state.addOrderStatus = "succeeded";
        state.orders.push(action.payload); // Add the new order to the list
      })
      .addCase(addOrder.rejected, (state, action) => {
        state.addOrderStatus = "failed";
        state.error = action.payload;
      })
      // Fetch All Orders
      .addCase(fetchAllOrders.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchAllOrders.fulfilled, (state, action) => {
        state.loading = false;
        state.allOrders = action.payload;
      })
      .addCase(fetchAllOrders.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // Update Order Item Status
      .addCase(updateOrderItemDeliveryStatus.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateOrderItemDeliveryStatus.fulfilled, (state, action) => {
        state.loading = false;
        const updatedOrderItem = action.payload;
        const order = state.orders.find(
          (order) => order.id === updatedOrderItem.orderId
        );
        if (order) {
          const orderItem = order.orderItems.find(
            (item) => item.id === updatedOrderItem.id
          );
          if (orderItem) {
            orderItem.deliveryStatus = updatedOrderItem.deliveryStatus;
          }
        }
      })
      .addCase(updateOrderItemDeliveryStatus.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

// Selector for raw orders state
export const selectOrders = (state) => state.orders.orders;

export const selectCompleteOrder = (state) => state.orders.completeOrder;
export const selectCompleteOrderItem = (state) =>
  state.orders.completeOrderItem;

export const selectAllOrders = (state) => state.orders.allOrders;

// Derived selector to transform orders data for UI
export const selectTransformedOrders = createSelector(
  [selectOrders],
  (orders) =>
    orders
      .filter(
        (order) =>
          order.payment_status === "COMPLETE" || order.paymentMethod === "EFT"
      )
      .map((order) => ({
        id: order.id,
        total: order.total,
        firstName: order.firstName,
        lastName: order.lastName,
        email: order.email,
        userId: order.userId,
        deliveryMethod: order.deliveryMethod,
        paymentMethod: order.paymentMethod,
        createdAt: order.createdAt,
        payment_status: order.payment_status,
        orderItems: order.orderItems.map((item) => ({
          id: item.id,
          quantity: item.quantity,
          title: item.title,
          unitPrice: item.digitPrice,
          digitPrice: item.digitPrice,
          preOrderPrice: item.preOrderPrice,
          totalPrice: item.totalPrice,
          pudoFee: item.pudoFee,
          deliveryGuyFee: item.deliveryGuyFee,
          image: item.image,
          isFullPayment: item.isFullPayment,
          pokemonId: item.pokemonId,
        })),
      }))
);

// complete orders with  delivery status == 'ToShip'

export const selectToShipOrders = createSelector([selectAllOrders], (orders) =>
  orders
    .filter(
      (order) =>
        order.payment_status === "COMPLETE" || order.paymentMethod === "EFT"
    )
    .map((order) => ({
      ...order,
      orderItems: order.orderItems.filter(
        (item) => item.deliveryStatus === "ToShip" || !item.deliveryStatus
      ),
    }))
    .filter((order) => order.orderItems.length > 0)
);

// complete orders with  delivery status == 'ToRecieve'

export const selectToRecieveOrders = createSelector(
  [selectAllOrders],
  (orders) =>
    orders
      .filter(
        (order) =>
          order.payment_status === "COMPLETE" || order.paymentMethod === "EFT"
      )
      .map((order) => ({
        ...order,
        orderItems: order.orderItems.filter(
          (item) => item.deliveryStatus === "ToRecieve"
        ),
      }))
      .filter((order) => order.orderItems.length > 0)
);

// complete orders with  delivery status == 'Completed'

export const selectCompletedOrders = createSelector(
  [selectAllOrders],
  (orders) =>
    orders
      .filter(
        (order) =>
          order.payment_status === "COMPLETE" || order.paymentMethod === "EFT"
      )
      .map((order) => ({
        ...order,
        orderItems: order.orderItems.filter(
          (item) => item.deliveryStatus === "Completed"
        ),
      }))
      .filter((order) => order.orderItems.length > 0)
);

// complete user orders with  delivery status == 'ToShip'

export const selectToShipUserOrders = createSelector([selectOrders], (orders) =>
  orders
    .filter(
      (order) =>
        order.payment_status === "COMPLETE" || order.paymentMethod === "EFT"
    )
    .map((order) => ({
      ...order,
      orderItems: order.orderItems.filter(
        (item) => item.deliveryStatus === "ToShip" || !item.deliveryStatus
      ),
    }))
    .filter((order) => order.orderItems.length > 0)
);

// complete user orders with  delivery status == 'ToRecieve'

export const selectToRecieveUserOrders = createSelector(
  [selectOrders],
  (orders) =>
    orders
      .filter(
        (order) =>
          order.payment_status === "COMPLETE" || order.paymentMethod === "EFT"
      )
      .map((order) => ({
        ...order,
        orderItems: order.orderItems.filter(
          (item) => item.deliveryStatus === "ToRecieve"
        ),
      }))
      .filter((order) => order.orderItems.length > 0)
);

// complete user orders with  delivery status == 'Completed'

export const selectCompletedUserOrders = createSelector(
  [selectOrders],
  (orders) =>
    orders
      .filter(
        (order) =>
          order.payment_status === "COMPLETE" || order.paymentMethod === "EFT"
      )
      .map((order) => ({
        ...order,
        orderItems: order.orderItems.filter(
          (item) => item.deliveryStatus === "Completed"
        ),
      }))
      .filter((order) => order.orderItems.length > 0)
);

export const { addCompleteOrder, addCompleteOrderItem } = ordersSlice.actions;
export default ordersSlice.reducer;
