import Link from "next/link";
import React from "react";
import { items_Properties_data } from "../../data/items_tabs_data";

const Properties = () => {
  return (
    <>
      {/* <!-- Properties --> */}
      <div
        className="tab-pane fade"
        id="properties"
        role="tabpanel"
        aria-labelledby="properties-tab"
      >
        <div className="dark:bg-jacarta-700 dark:border-jacarta-600 border-jacarta-100 rounded-t-2lg rounded-b-2lg rounded-tl-none border bg-white p-6 md:p-10">
          <div className="grid grid-cols-2 gap-5 sm:grid-cols-3 md:grid-cols-4"></div>
        </div>
      </div>
    </>
  );
};

export default Properties;
