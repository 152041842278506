import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, EffectCoverflow, Ally } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import { coverflow_data } from "../../data/coverflow_data";
import Link from "next/link";
import Image from "next/legacy/image";
import { useEffect, useState } from "react";
import { Skeleton } from "../skeleton";
import { useSelector, useDispatch } from "react-redux";
import {
  fetchPokemons,
  selectTransformedPokemons,
} from "../../redux/pokemonSlice";
const CoverflowCarousel = () => {
  const dispatch = useDispatch();
  const { pokemons, loading, error } = useSelector((state) => state.pokemon);
  const selectedPokemons = useSelector(selectTransformedPokemons); // Select transformed pokemons

  useEffect(() => {
    dispatch(fetchPokemons());
  }, [dispatch]);

  if (loading) return <Skeleton />;

  return (
    <>
      {/* <!-- Coverflow Slider --> */}
      <div className="relative px-6 pb-16 sm:px-0">
        {/* <!-- Slider --> */}
        <Swiper
          breakpoints={{
            // when window width is >= 640px
            100: {
              // width: 640,
              slidesPerView: 1,
            },
            575: {
              // width: 640,
              slidesPerView: 3,
            },
            // when window width is >= 768px
            992: {
              // width: 768,
              slidesPerView: 5,
            },
          }}
          effect={"coverflow"}
          grabCursor={true}
          centeredSlides={true}
          slidesPerView={5}
          loop={true}
          coverflowEffect={{
            rotate: 30,
            stretch: 0,
            depth: 100,
            modifier: 1,
            slideShadows: true,
          }}
          pagination={true}
          modules={[EffectCoverflow, Pagination, Navigation]}
          navigation={{
            nextEl: ".swiper-button-next-4",
            prevEl: ".swiper-button-prev-4",
          }}
          className="swiper coverflow-slider !py-5"
        >
          {selectedPokemons &&
            selectedPokemons.length > 0 &&
            selectedPokemons.map((item) => {
              const {
                title,
                description,
                digitPrice,
                preOrderPrice,
                imageList,
                productQuantity,
                id,
              } = item;

              return (
                <SwiperSlide key={id}>
                  <article>
                    <div className="block overflow-hidden rounded-2.5xl bg-white shadow-md transition-shadow hover:shadow-lg dark:bg-jacarta-700">
                      <figure className="relative">
                        <Link href={`/product/${id}`}>
                          <Image
                            src={imageList[0]}
                            alt={title}
                            className="swiper-lazy h-[430px] w-full object-cover"
                            height="430"
                            width="379"
                          />
                        </Link>
                      </figure>
                      <div className="p-6">
                        <div className="flex">
                          <div>
                            <Link href={`/product/${id}`} className="block">
                              <span className="font-display text-lg leading-none text-jacarta-700 hover:text-accent dark:text-white line-clamp-2">
                                {title}
                              </span>
                            </Link>
                          </div>
                        </div>
                        <div className="flex mt-2 items-center justify-between">
                          <Link
                            href={`/product/${id}`}
                            className="text-sm text-accent font-semibold"
                          >
                            R{digitPrice.toFixed(2)}
                          </Link>

                          <div className="mt-2 flex items-center justify-between">
                            <button
                              className={` font-display text-sm font-semibold ${
                                productQuantity === 0
                                  ? "text-accent"
                                  : "text-green"
                              }`}
                              onClick={() => router.push(`/product/${id}`)}
                            >
                              {productQuantity === 0 ? "Pre order" : "Buy now"}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </article>
                </SwiperSlide>
              );
            })}
        </Swiper>

        <div className="swiper-button-prev-4 group absolute top-1/2 left-4 z-10 -mt-6 flex h-12 w-12 cursor-pointer items-center justify-center rounded-full bg-white p-3 text-jacarta-700 text-xl shadow-white-volume">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="24"
            height="24"
            className="fill-jacarta-700 group-hover:fill-accent"
          >
            <path fill="none" d="M0 0h24v24H0z" />
            <path d="M10.828 12l4.95 4.95-1.414 1.414L8 12l6.364-6.364 1.414 1.414z" />
          </svg>
        </div>
        <div className="swiper-button-next-4 group absolute top-1/2 right-4 z-10 -mt-6 flex h-12 w-12 cursor-pointer items-center justify-center rounded-full bg-white p-3 text-jacarta-700 text-xl shadow-white-volume">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="24"
            height="24"
            className="fill-jacarta-700 group-hover:fill-accent"
          >
            <path fill="none" d="M0 0h24v24H0z" />
            <path d="M13.172 12l-4.95-4.95 1.414-1.414L16 12l-6.364 6.364-1.414-1.414z" />
          </svg>
        </div>

        {/* <!-- end coverflow slider --> */}
      </div>
    </>
  );
};

export default CoverflowCarousel;
