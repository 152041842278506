import React from "react";
import Image from "next/legacy/image";
import Link from "next/link";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import Likes from "../likes";
import { useDispatch, useSelector } from "react-redux";
import { useRouter } from "next/navigation";

const CategoryItem = () => {
  const { sortedtrendingCategoryItemData } = useSelector(
    (state) => state.counter
  );
  const dispatch = useDispatch();
  const router = useRouter();

  return (
    <div className="grid grid-cols-1 gap-[1.875rem] md:grid-cols-2 lg:grid-cols-4">
      {sortedtrendingCategoryItemData.map((item) => {
        const {
          id,
          image,
          title,
          price,
          productQuantity,
          bidLimit,
          bidCount,
          likes,
          creator,
          owner,
        } = item;
        const itemLink = image
          .split("/")
          .slice(-1)
          .toString()
          .replace(".jpg", "")
          .replace(".gif", "");
        return (
          <article key={id}>
            <div className="dark:bg-jacarta-700 dark:border-jacarta-700 border-jacarta-100 rounded-2.5xl block border bg-white p-[1.1875rem] transition-shadow hover:shadow-lg">
              <figure className="relative">
                <Link href={`/product/${id}`}>
                  <img
                    src={image} //288X230
                    alt="item 5"
                    className="w-full h-[230px] rounded-[0.625rem] object-cover"
                  />
                </Link>

                <Likes like={likes} />

                <div className="absolute left-3 -bottom-3">
                  <div className="flex -space-x-2">
                    <Link href={`/product/${id}`}>
                      <Tippy content={<span>creator: {creator.name}</span>}>
                        <Image
                          src={creator.image}
                          width={24}
                          height={24}
                          alt="creator"
                          className="dark:border-jacarta-600 hover:border-accent dark:hover:border-accent h-6 w-6 rounded-full border-2 border-white"
                        />
                      </Tippy>
                    </Link>
                    <Link href={`/product/${id}`}>
                      <Tippy content={<span>creator: {owner.name}</span>}>
                        <img
                          src={owner.image}
                          alt="owner"
                          layout="fill"
                          className="dark:border-jacarta-600 hover:border-accent dark:hover:border-accent h-6 w-6 rounded-full border-2 border-white"
                        />
                      </Tippy>
                    </Link>
                  </div>
                </div>
              </figure>
              <div className="mt-7 flex items-center justify-between">
                <Link href={`/product/${id}`}>
                  <span className="font-display text-jacarta-700 hover:text-accent text-base dark:text-white  line-clamp-2">
                    {title}
                  </span>
                </Link>

                {/* auction dropdown  */}
                {/* <Auctions_dropdown classes="dark:hover:bg-jacarta-600 dropup hover:bg-jacarta-100 rounded-full " /> */}
              </div>
              <div className="mt-4 flex items-center justify-between">
                <span className="text-accent mr-1 text-sm">{price}</span>
                <button
                  className={` font-display text-sm font-semibold ${
                    productQuantity === 0 ? "text-accent" : "text-green"
                  }`}
                  onClick={() => router.push(`/product/${id}`)}
                >
                  {productQuantity === 0 ? "Pre order" : "Buy now"}
                </button>
              </div>
            </div>
          </article>
        );
      })}
    </div>
  );
};

export default CategoryItem;
