// src/store/slices/cartSlice.js
import { createSlice } from "@reduxjs/toolkit";

const cartSlice = createSlice({
  name: "cart",
  initialState: {
    items: [], // Array to store cart items
    total: 0, // Total price
    totalDeliveryGuyFee: 0, // Reset totalDeliveryGuyFee total
    totalPudoFee: 0, // Reset totalPudoFee total
    cartHasFigurines: false,
  },
  reducers: {
    addItemToCart: (state, action) => {
      const item = action.payload;

      // Check if the item already exists in the cart
      const existingItem = state.items.find(
        (cartItem) => cartItem.id === item.id
      );

      if (existingItem) {
        existingItem.quantity = item.quantity; // Increment quantity if the item exists
        existingItem.isFullPayment = item.isFullPayment;
      } else {
        state.items.push({
          ...item,
          deliveryStatus: "ToShip", // Add a new item with quantity 1
        });
      }

      const totalPrice = state.items.reduce(
        (total, item) =>
          total +
          (item.isFullPayment ? item.digitPrice : item.preOrderPrice) *
            item.quantity,
        0
      );

      // Update the total price
      state.total = totalPrice;

      // Update the total totalPudoFee
      const totalPudoFee = state.items.reduce(
        (total, item) =>
          total + (item.isFullPayment ? item.pudoFee : 0) * item.quantity,
        0
      );
      state.totalPudoFee = totalPudoFee;

      // Update the total totalDeliveryGuyFee
      const totalDeliveryGuyFee = state.items.reduce(
        (total, item) =>
          total +
          (item.isFullPayment ? item.deliveryGuyFee : 0) * item.quantity,
        0
      );
      state.totalDeliveryGuyFee = totalDeliveryGuyFee;

      const cartHasFigurines = state.items.some(
        (item) => !item.isCard && item.isFullPayment
      );
      state.cartHasFigurines = cartHasFigurines;
    },
    removeItemFromCart: (state, action) => {
      const itemId = action.payload;
      state.items = state.items.filter((item) => item.id !== itemId);
      const totalPrice = state.items.reduce(
        (total, item) =>
          total +
          (item.isFullPayment ? item.digitPrice : item.preOrderPrice) *
            item.quantity,
        0
      );
      // Update the total price
      state.total = totalPrice;

      // Update the total totalPudoFee
      const totalPudoFee = state.items.reduce(
        (total, item) =>
          total + (item.isFullPayment ? item.pudoFee : 0) * item.quantity,
        0
      );
      state.totalPudoFee = totalPudoFee;

      // Update the total totalDeliveryGuyFee
      const totalDeliveryGuyFee = state.items.reduce(
        (total, item) =>
          total +
          (item.isFullPayment ? item.deliveryGuyFee : 0) * item.quantity,
        0
      );
      state.totalDeliveryGuyFee = totalDeliveryGuyFee;

      const cartHasFigurines = state.items.some(
        (item) => !item.isCard && !item.isFullPayment
      );
      state.cartHasFigurines = cartHasFigurines;
    },
    clearCart: (state) => {
      state.items = []; // Reset items
      state.total = 0; // Reset total
      state.totalDeliveryGuyFee = 0; // Reset totalDeliveryGuyFee total
      state.totalPudoFee = 0; // Reset totalPudoFee total
      state.cartHasFigurines = false; // Reset cartHasFigurines
    },
  },
});

export const { addItemToCart, removeItemFromCart, clearCart } =
  cartSlice.actions;

export const selectCart = (state) => state.cart;

// Selector to get the cart items
export const selectCartItems = (state) => state.cart.items;

// Selector to get the total price
export const selectCartTotal = (state) => state.cart.total;

export default cartSlice.reducer;
