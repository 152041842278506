import React from "react";
import Welcome from "./home/home_4";

export default function Home() {
  return (
    <>
      <Welcome />
    </>
  );
}
