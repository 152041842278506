import React from "react";
import Image from "next/image";
import { beautifyTimestamp } from "../../utils/toLocaleString";
import { completePaymentModalShow } from "../../redux/counterSlice";
import { useDispatch } from "react-redux";
import {
  addCompleteOrder,
  addCompleteOrderItem,
} from "../../redux/ordersSlice";
import EmptyState from "../empty-state";

const OrderList = ({ orders }) => {
  const dispatch = useDispatch();

  const handleComopletePayment = (order, orderItem) => {
    dispatch(addCompleteOrder(order));
    dispatch(addCompleteOrderItem(orderItem));
    dispatch(completePaymentModalShow());
  };

  return (
    <>
      <div class="relative overflow-x-auto">
        <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
          <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" class="px-6 py-3 min-w-[260px]">
                Item
              </th>
              <th scope="col" class="px-6 py-3">
                Quantity
              </th>
              <th scope="col" class="px-6 py-3">
                Price
              </th>
              <th scope="col" class="px-6 py-3">
                Note
              </th>
              <th scope="col" class="px-6 py-3">
                Outstanding Balance
              </th>
              <th scope="col" class="px-6 py-3">
                Payment Status
              </th>
              <th scope="col" class="px-6 py-3">
                Order Date
              </th>
              <th scope="col" class="px-6 py-3">
                Delivery Method
              </th>
            </tr>
          </thead>
          {orders.length > 0 ? (
            <tbody>
              {orders.map((order) => {
                return (
                  <>
                    {order.orderItems.map((orderItem, id) => {
                      const {
                        image,
                        title,
                        isFullPayment,
                        pokemonId,
                        unitPrice,
                        digitPrice,
                        preOrderPrice,
                        totalPrice,
                        quantity,
                      } = orderItem;
                      return (
                        <>
                          <tr
                            class="dark:bg-jacarta-700 border-b dark:bg-gray-800 dark:border-jacarta-600"
                            key={id}
                          >
                            <th
                              scope="row"
                              class="px-6 py-4 font-medium text-gray-900 dark:text-white"
                            >
                              <div className="flex gap-2">
                                <figure className="self-start w-[60px]">
                                  <Image
                                    src={image}
                                    alt="avatar 2"
                                    width={60}
                                    height={60}
                                    className="lg:rounded-2lg lg:w-[60px] lg:h-[60px] md:h-[30px] rounded-lg"
                                    loading="lazy"
                                  />
                                </figure>

                                <div>
                                  <a
                                    href={`/product/${pokemonId}`}
                                    className="text-accent text-m text-ellipsis text-wrap"
                                  >
                                    {title}
                                  </a>
                                  <div className="flex flex-wrap items-center">
                                    <span className="dark:text-jacarta-300 text-jacarta-500 mr-1 block text-sm">
                                      {isFullPayment
                                        ? "Full Payment"
                                        : "Partial Payment"}
                                    </span>
                                  </div>
                                  {!isFullPayment && (
                                    <div className="flex flex-wrap items-center mt-2">
                                      <button
                                        className="text-accent font-display text-sm font-semibold"
                                        onClick={() =>
                                          handleComopletePayment(
                                            order,
                                            orderItem
                                          )
                                        }
                                      >
                                        Complete Payment
                                      </button>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </th>
                            <td class="px-6 py-4"> {quantity}</td>
                            <td class="px-6 py-4"> {totalPrice}</td>
                            <td class="px-6 py-4">
                              {isFullPayment
                                ? "Full Payment"
                                : "Partial Payment"}
                            </td>
                            <td class="px-6 py-4">
                              {isFullPayment
                                ? "N/A"
                                : (digitPrice - preOrderPrice) * quantity}
                            </td>
                            <td class="px-6 py-4">
                              {" "}
                              {order.payment_status || order.paymentMethod}
                            </td>
                            <td class="px-6 py-4">
                              {" "}
                              {beautifyTimestamp(order.createdAt)}
                            </td>
                            <td class="px-6 py-4"> {order.deliveryMethod}</td>
                          </tr>
                        </>
                      );
                    })}
                    <div className="h-2"></div>
                  </>
                );
              })}
            </tbody>
          ) : (
            <tbody>
              <tr>
                <td className="text-center" colSpan="8">
                  <EmptyState message="No Orders" />
                </td>
              </tr>
            </tbody>
          )}
        </table>
      </div>
    </>
  );
};

export default OrderList;
