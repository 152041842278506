import React, { useEffect, useState } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAllOrders,
  fetchOrdersByUserId,
  selectAllOrders,
  selectOrders,
  selectToRecieveOrders,
  selectToShipOrders,
  selectTransformedOrders,
} from "../../../redux/ordersSlice";
import { Skeleton } from "../../skeleton";
import { selectLoading, selectUser } from "../../../redux/userSlice";
import OrderList from "./OrderList";
import Properties from "./Properties";
import ToReceiveOrderList from "./ToReceiveOrderList";
import withAuthAdmin from "../../../utils/withAuthAdmin";

const ItemsTabs = () => {
  const dispatch = useDispatch();
  const [tabsActive, setTabsActive] = useState(1);
  const tabsHeadText = [
    {
      id: 1,
      text: "To Ship",
      icon: "offers",
    },
    {
      id: 2,
      text: "To Receive",
      icon: "properties",
    },
    {
      id: 3,
      text: "Completed",
      icon: "details",
    },
  ];
  useEffect(() => {
    dispatch(fetchAllOrders());
  }, [dispatch]);

  const isLoading = useSelector(selectLoading);

  const loading = useSelector((state) => state.orders.loading);
  const ordersToShip = useSelector(selectToShipOrders);
  const ordersToReceive = useSelector(selectToRecieveOrders);

  if (loading || isLoading) {
    return (
      <>
        <div className="mt-[80px]">
          <Skeleton />
          <Skeleton />
        </div>
      </>
    );
  }

  return (
    <>
      <div className="scrollbar-custom mt-14 overflow-x-auto rounded-lg h-full">
        {/* <!-- Tabs Nav --> */}
        <Tabs className="min-w-full tabs h-full">
          <TabList className="nav nav-tabs flex items-center">
            {/* <!-- Offers --> */}
            {tabsHeadText.map(({ id, text, icon }) => {
              return (
                <Tab className="nav-item bg-transparent" key={id}>
                  <button
                    className={
                      tabsActive === id
                        ? "nav-link hover:text-jacarta-700 text-jacarta-400 relative flex items-center whitespace-nowrap py-3 px-6 dark:hover:text-white active"
                        : "nav-link hover:text-jacarta-700 text-jacarta-400 relative flex items-center whitespace-nowrap py-3 px-6 dark:hover:text-white"
                    }
                    onClick={() => setTabsActive(id)}
                  >
                    <svg className="icon mr-1 h-5 w-5 fill-current">
                      <use xlinkHref={`/icons.svg#icon-${icon}`}></use>
                    </svg>
                    <span className="font-display text-base font-medium">
                      {text}
                    </span>
                  </button>
                </Tab>
              );
            })}
          </TabList>

          <TabPanel className="h-full">
            <OrderList orders={ordersToShip} />
          </TabPanel>
          <TabPanel>
            <ToReceiveOrderList orders={ordersToReceive} />
          </TabPanel>
          <TabPanel>
            {/* <!-- Details --> */}
            <div
              className="tab-pane fade"
              id="details"
              role="tabpanel"
              aria-labelledby="details-tab"
            >
              <div className="dark:bg-jacarta-700 dark:border-jacarta-600 border-jacarta-100 rounded-t-2lg rounded-b-2lg rounded-tl-none border bg-white p-6 md:p-10"></div>
            </div>
          </TabPanel>
        </Tabs>
      </div>
    </>
  );
};

export default withAuthAdmin(ItemsTabs);
