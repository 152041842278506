const tranding_category_filter = [
  {
    id: 0,
    text: "all",
  },
  {
    id: 1,
    svg: "art",
    text: "Pokemon Cards",
  },

  {
    id: 2,
    svg: "collection",
    text: "Pokemon Figurines",
  },
  {
    id: 3,
    svg: "domain",
    text: "Dragon Ball Z Figurines",
  },
  {
    id: 4,
    svg: "properties",
    text: "Demon Slayer",
  },
];

const trendingCategoryData = [
  {
    image:
      "https://images.ctfassets.net/x7xzeldz66so/12RGz8wBdq3pUAxz1DEg89/d6e190bb47b0d941d71c63332e44e983/Screenshot_20241122_202318_Gallery.jpg",
    id: "Flourishing Cat #1800",
    category: "art",
    title: "Flourishing Cat #180",
    nfsw: true,
    lazyMinted: false,
    verified: true,
    addDate: 1,
    sortPrice: 8.49,
    price: "From 8.49 ETH",
    bidLimit: 8,
    bidCount: 2,
    likes: 15,
    creator: {
      name: "Sussygirl",
      image: "/images/avatars/creator_1.png",
    },
    owner: {
      name: "Sussygirl",
      image: "/images/avatars/owner_1.png",
    },
  },
  {
    image:
      "https://images.ctfassets.net/x7xzeldz66so/3jvqktYGw2zY9y0uBa5SSb/bd93a5025c6cb7198cc16e5ceaeda662/Screenshot_20241122_085200_Gallery.jpg",
    id: "Amazing NFT art1",
    category: "Collectibles",
    title: "Amazing NFT art",
    nfsw: true,
    lazyMinted: false,
    verified: false,
    addDate: 2,
    sortPrice: 5.9,
    price: "From 5.9 ETH",
    bidLimit: 7,
    bidCount: 1,
    likes: 188,
    creator: {
      name: "Sussygirl",
      image: "/images/avatars/creator_2.png",
    },
    owner: {
      name: "Sussygirl",
      image: "/images/avatars/owner_2.png",
    },
  },
  {
    image: "/images/products/item_7.jpg",
    id: "SwagFox#1332",
    category: "domain",
    title: "SwagFox#133",
    nfsw: false,
    lazyMinted: true,
    verified: true,
    addDate: 3,
    sortPrice: 0.078,
    price: "0.078 ETH",
    bidLimit: 3,
    bidCount: 1,
    likes: 160,
    creator: {
      name: "Sussygirl",
      image: "/images/avatars/creator_3.png",
    },
    owner: {
      name: "Sussygirl",
      image: "/images/avatars/owner_3.png",
    },
  },
  {
    image: "/images/products/item_6.jpg",
    id: "Splendid Girl3",
    category: "music",
    title: "Splendid Girl",
    nfsw: true,
    lazyMinted: true,
    verified: false,
    addDate: 4,
    sortPrice: 10,
    price: "10 ETH",
    bidLimit: 3,
    bidCount: 2,
    likes: 159,
    creator: {
      name: "Sussygirl",
      image: "/images/avatars/creator_4.png",
    },
    owner: {
      name: "Sussygirl",
      image: "/images/avatars/owner_4.png",
    },
  },
  {
    image: "/images/products/item_8.jpg",
    id: "Monkeyme#1554",
    category: "photography",
    title: "Monkeyme#155",
    nfsw: false,
    lazyMinted: false,
    verified: true,
    addDate: 5,
    sortPrice: 5,
    price: "From 5 FLOW",
    bidLimit: 1,
    bidCount: 1,
    likes: 32,
    creator: {
      name: "Sussygirl",
      image: "/images/avatars/creator_3.png",
    },
    owner: {
      name: "Sussygirl",
      image: "/images/avatars/owner_5.png",
    },
  },
  {
    image: "/images/products/item_9.jpg",
    id: "Jedidia#1495",
    category: "virtual world",
    title: "Jedidia#149",
    nfsw: true,
    lazyMinted: false,
    verified: true,
    addDate: 6,
    sortPrice: 0.16,
    price: "0.16 ETH",
    bidLimit: 1,
    bidCount: 1,
    likes: 25,
    creator: {
      name: "Sussygirl",
      image: "/images/avatars/creator_6.png",
    },
    owner: {
      name: "Sussygirl",
      image: "/images/avatars/owner_4.png",
    },
  },
  {
    image: "/images/products/item_10.jpg",
    id: "Artof Eve6",
    category: "art",
    title: "Artof Eve",
    nfsw: true,
    lazyMinted: false,
    verified: false,
    addDate: 7,
    sortPrice: 0.13,
    price: "0.13 FLOW",
    bidLimit: 1,
    bidCount: 1,
    likes: 55,
    creator: {
      name: "Sussygirl",
      image: "/images/avatars/creator_2.png",
    },
    owner: {
      name: "Sussygirl",
      image: "/images/avatars/owner_7.png",
    },
  },
  {
    image: "/images/products/item_11.jpg",
    id: "Asuna #16497",
    category: "collection",
    title: "Asuna#1649",
    nfsw: false,
    lazyMinted: true,
    verified: true,
    addDate: 8,
    sortPrice: 0.8,
    price: "0.8 ETH",
    bidLimit: 1,
    bidCount: 1,
    likes: 70,
    creator: {
      name: "Sussygirl",
      image: "/images/avatars/creator_8.png",
    },
    owner: {
      name: "Sussygirl",
      image: "/images/avatars/owner_5.png",
    },
  },
  {
    image: "/images/products/item_11.jpg",
    id: "Asuna#164978",
    category: "collection",
    title: "Asuna#1649",
    nfsw: true,
    lazyMinted: false,
    verified: false,
    addDate: 9,
    sortPrice: 0.8,
    price: "0.8 ETH",
    bidLimit: 1,
    bidCount: 1,
    likes: 70,
    creator: {
      name: "Sussygirl",
      image: "/images/avatars/creator_8.png",
    },
    owner: {
      name: "Sussygirl",
      image: "/images/avatars/owner_5.png",
    },
  },
  {
    image: "/images/products/item_12_lg.jpg",
    id: "Amazing NFT art9",
    category: "Collectibles",
    title: "Amazing NFT art",
    nfsw: false,
    lazyMinted: true,
    verified: true,
    addDate: 10,
    sortPrice: 5.9,
    price: "From 5.9 ETH",
    bidLimit: 7,
    bidCount: 1,
    likes: 188,
    creator: {
      name: "Sussygirl",
      image: "/images/avatars/creator_2.png",
    },
    owner: {
      name: "Sussygirl",
      image: "/images/avatars/owner_2.png",
    },
  },

  {
    image: "/images/products/item_13_lg.jpg",
    id: "SwagFox#13310",
    category: "domain",
    title: "SwagFox#133",
    nfsw: true,
    lazyMinted: false,
    verified: true,
    addDate: 12,
    sortPrice: 0.078,
    price: "0.078 ETH",
    bidLimit: 3,
    bidCount: 1,
    likes: 160,
    creator: {
      name: "Sussygirl",
      image: "/images/avatars/creator_3.png",
    },
    owner: {
      name: "Sussygirl",
      image: "/images/avatars/owner_3.png",
    },
  },
  {
    image: "/images/products/item_14_lg.jpg",
    id: "Splendid Girl311",
    category: "music",
    title: "Splendid Girl",
    nfsw: true,
    lazyMinted: false,
    verified: false,
    addDate: 13,
    sortPrice: 10,
    price: "10 ETH",
    bidLimit: 3,
    bidCount: 2,
    likes: 159,
    creator: {
      name: "Sussygirl",
      image: "/images/avatars/creator_4.png",
    },
    owner: {
      name: "Sussygirl",
      image: "/images/avatars/owner_4.png",
    },
  },
  {
    image: "/images/products/item_15_lg.jpg",
    id: "Monkeyme#15512",
    category: "photography",
    title: "Monkeyme#155",
    nfsw: false,
    lazyMinted: true,
    verified: true,
    addDate: 14,
    sortPrice: 5,
    price: "From 5 FLOW",
    bidLimit: 1,
    bidCount: 1,
    likes: 32,
    creator: {
      name: "Sussygirl",
      image: "/images/avatars/creator_3.png",
    },
    owner: {
      name: "Sussygirl",
      image: "/images/avatars/owner_5.png",
    },
  },
  {
    image: "/images/products/item_16_lg.jpg",
    id: "Jedidia#14913",
    category: "virtual world",
    title: "Jedidia#149",
    nfsw: true,
    lazyMinted: false,
    verified: true,
    addDate: 15,
    sortPrice: 0.16,
    price: "0.16 ETH",
    bidLimit: 1,
    bidCount: 1,
    likes: 25,
    creator: {
      name: "Sussygirl",
      image: "/images/avatars/creator_6.png",
    },
    owner: {
      name: "Sussygirl",
      image: "/images/avatars/owner_4.png",
    },
  },

  {
    image: "/images/products/item_5.jpg",
    id: "FlourishingCat#18014",
    category: "art",
    title: "Flourishing Cat #180",
    nfsw: true,
    lazyMinted: false,
    verified: true,
    addDate: 16,
    sortPrice: 8.49,
    price: "From 8.49 ETH",
    bidLimit: 8,
    bidCount: 2,
    likes: 15,
    creator: {
      name: "Sussygirl",
      image: "/images/avatars/creator_1.png",
    },
    owner: {
      name: "Sussygirl",
      image: "/images/avatars/owner_1.png",
    },
  },
  {
    image: "/images/products/item_4.jpg",
    id: "Amazing NFT art15",
    category: "Collectibles",
    title: "Amazing NFT art",
    nfsw: false,
    lazyMinted: true,
    verified: true,
    addDate: 16,
    sortPrice: 5.9,
    price: "From 5.9 ETH",
    bidLimit: 7,
    bidCount: 1,
    likes: 188,
    creator: {
      name: "Sussygirl",
      image: "/images/avatars/creator_2.png",
    },
    owner: {
      name: "Sussygirl",
      image: "/images/avatars/owner_2.png",
    },
  },
  {
    image: "/images/products/item_7.jpg",
    id: "SwagFox#13316",
    category: "domain",
    title: "SwagFox#133",
    nfsw: false,
    lazyMinted: false,
    verified: false,
    addDate: 17,
    sortPrice: 0.078,
    price: "0.078 ETH",
    bidLimit: 3,
    bidCount: 1,
    likes: 160,
    creator: {
      name: "Sussygirl",
      image: "/images/avatars/creator_3.png",
    },
    owner: {
      name: "Sussygirl",
      image: "/images/avatars/owner_3.png",
    },
  },
  {
    image: "/images/products/item_6.jpg",
    id: "Splendid Girl7",
    category: "music",
    title: "Splendid Girl",
    nfsw: true,
    lazyMinted: false,
    verified: true,
    addDate: 18,
    sortPrice: 10,
    price: "10 ETH",
    bidLimit: 3,
    bidCount: 2,
    likes: 159,
    creator: {
      name: "Sussygirl",
      image: "/images/avatars/creator_4.png",
    },
    owner: {
      name: "Sussygirl",
      image: "/images/avatars/owner_4.png",
    },
  },
  {
    image: "/images/products/item_8.jpg",
    id: "Monkeyme#15548",
    category: "photography",
    title: "Monkeyme#155",
    nfsw: false,
    lazyMinted: true,
    verified: true,
    addDate: 19,
    sortPrice: 5,
    price: "From 5 FLOW",
    bidLimit: 1,
    bidCount: 1,
    likes: 32,
    creator: {
      name: "Sussygirl",
      image: "/images/avatars/creator_3.png",
    },
    owner: {
      name: "Sussygirl",
      image: "/images/avatars/owner_5.png",
    },
  },
  {
    image: "/images/products/item_9.jpg",
    id: "Jedidia#14959",
    category: "virtual world",
    title: "Jedidia#149",
    nfsw: true,
    lazyMinted: false,
    verified: true,
    addDate: 20,
    sortPrice: 0.16,
    price: "0.16 ETH",
    bidLimit: 1,
    bidCount: 1,
    likes: 25,
    creator: {
      name: "Sussygirl",
      image: "/images/avatars/creator_6.png",
    },
    owner: {
      name: "Sussygirl",
      image: "/images/avatars/owner_4.png",
    },
  },
  {
    image: "/images/products/item_10.jpg",
    id: "Artof Eve60",
    category: "art",
    title: "Artof Eve",
    nfsw: true,
    lazyMinted: false,
    verified: false,
    addDate: 21,
    sortPrice: 0.13,
    price: "0.13 FLOW",
    bidLimit: 1,
    bidCount: 1,
    likes: 55,
    creator: {
      name: "Sussygirl",
      image: "/images/avatars/creator_2.png",
    },
    owner: {
      name: "Sussygirl",
      image: "/images/avatars/owner_7.png",
    },
  },
  {
    image: "/images/products/item_11.jpg",
    id: "Asuna #164971",
    category: "collection",
    title: "Asuna#1649",
    nfsw: false,
    lazyMinted: true,
    verified: true,
    addDate: 22,
    sortPrice: 0.8,
    price: "0.8 ETH",
    bidLimit: 1,
    bidCount: 1,
    likes: 70,
    creator: {
      name: "Sussygirl",
      image: "/images/avatars/creator_8.png",
    },
    owner: {
      name: "Sussygirl",
      image: "/images/avatars/owner_5.png",
    },
  },
  {
    image: "/images/products/item_11.jpg",
    id: "Asuna#1649782",
    category: "collection",
    title: "Asuna#1649",
    nfsw: true,
    lazyMinted: false,
    verified: true,
    addDate: 23,
    sortPrice: 0.8,
    price: "0.8 ETH",
    bidLimit: 1,
    bidCount: 1,
    likes: 70,
    creator: {
      name: "Sussygirl",
      image: "/images/avatars/creator_8.png",
    },
    owner: {
      name: "Sussygirl",
      image: "/images/avatars/owner_5.png",
    },
  },
  {
    image: "/images/products/item_12_lg.jpg",
    id: "Amazing NFT art93",
    category: "Collectibles",
    title: "Amazing NFT art",
    nfsw: true,
    lazyMinted: false,
    verified: true,
    addDate: 24,
    sortPrice: 5.9,
    price: "From 5.9 ETH",
    bidLimit: 7,
    bidCount: 1,
    likes: 188,
    creator: {
      name: "Sussygirl",
      image: "/images/avatars/creator_2.png",
    },
    owner: {
      name: "Sussygirl",
      image: "/images/avatars/owner_2.png",
    },
  },

  {
    image: "/images/products/item_13_lg.jpg",
    id: "SwagFox#133104",
    category: "domain",
    title: "SwagFox#133",
    nfsw: false,
    lazyMinted: true,
    verified: false,
    addDate: 25,
    sortPrice: 0.078,
    price: "0.078 ETH",
    bidLimit: 3,
    bidCount: 1,
    likes: 160,
    creator: {
      name: "Sussygirl",
      image: "/images/avatars/creator_3.png",
    },
    owner: {
      name: "Sussygirl",
      image: "/images/avatars/owner_3.png",
    },
  },
  {
    image: "/images/products/item_14_lg.jpg",
    id: "Splendid Girl3115",
    category: "music",
    title: "Splendid Girl",
    nfsw: true,
    lazyMinted: false,
    verified: true,
    addDate: 26,
    sortPrice: 10,
    price: "10 ETH",
    bidLimit: 3,
    bidCount: 2,
    likes: 159,
    creator: {
      name: "Sussygirl",
      image: "/images/avatars/creator_4.png",
    },
    owner: {
      name: "Sussygirl",
      image: "/images/avatars/owner_4.png",
    },
  },
  {
    image: "/images/products/item_15_lg.jpg",
    id: "Monkeyme#155126",
    category: "photography",
    title: "Monkeyme#155",
    nfsw: true,
    lazyMinted: false,
    verified: true,
    addDate: 27,
    sortPrice: 5,
    price: "From 5 FLOW",
    bidLimit: 1,
    bidCount: 1,
    likes: 32,
    creator: {
      name: "Sussygirl",
      image: "/images/avatars/creator_3.png",
    },
    owner: {
      name: "Sussygirl",
      image: "/images/avatars/owner_5.png",
    },
  },
  {
    image: "/images/products/item_16_lg.jpg",
    id: "Jedidia#149137",
    category: "virtual world",
    title: "Jedidia#149",
    nfsw: false,
    lazyMinted: true,
    verified: false,
    addDate: 28,
    sortPrice: 0.16,
    price: "0.16 ETH",
    bidLimit: 1,
    bidCount: 1,
    likes: 25,
    creator: {
      name: "Sussygirl",
      image: "/images/avatars/creator_6.png",
    },
    owner: {
      name: "Sussygirl",
      image: "/images/avatars/owner_4.png",
    },
  },
];

export { tranding_category_filter, trendingCategoryData };
