import Head from "next/head";

const Meta = ({ title, keyword, desc }) => {
  return (
    <div>
      <Head>
        <title>{title}</title>
        <link rel="icon" href="/favicon.png" />
        <meta name="description" content={desc} />
        <meta name="keyword" content={keyword} />
        <meta
          name="google-site-verification"
          content="IEFlpv2smXkZAJOsToS31KnIOSjhum7fncH0zwuJfXk"
        />
      </Head>
    </div>
  );
};

Meta.defaultProps = {
  title: "Ixcitement Store - Premium Collectibles",
  keyword:
    "Pokemon cards, Pokemon figurines, Dragon Ball Z collectibles, anime figurines, trading cards, premium collectibles, top ecommerce platform, Silicon Valley startup, rare items, collectible marketplace, limited edition, buy Pokemon cards, shop Dragon Ball Z figurines, digital shopping experience",
  desc: "Discover the ultimate destination for premium collectibles. Shop authentic Pokemon cards, exclusive figurines, and rare Dragon Ball Z items. Ixcitement Store offers a seamless shopping experience powered by Silicon Valley innovation.",
};

export default Meta;
