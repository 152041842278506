import Link from "next/link";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { completePaymentModalHide } from "../../redux/counterSlice";
import LoadingButton from "../loading-button";
import { useRouter } from "next/router";
import Image from "next/image";
import {
  selectCompleteOrder,
  selectCompleteOrderItem,
} from "../../redux/ordersSlice";
import { generateSignature } from "../../utils/generateSignature";

const CompletePaymentModal = () => {
  const dispatch = useDispatch();
  const [buyNowTerms, setBuyNowTerms] = React.useState(false);

  const [isPudo, setIsPudo] = useState(true);
  const [totalPayment, setTotalPayment] = useState(0);

  const router = useRouter();
  const { completePaymentModal } = useSelector((state) => state.counter);

  const completeOrder = useSelector(selectCompleteOrder);
  const completeOrderItem = useSelector(selectCompleteOrderItem);

  useEffect(() => {
    if (completeOrder) {
      setIsPudo(completeOrder.deliveryMethod === "PUDO");
    }
  }, [completeOrder]);

  useEffect(() => {
    let total =
      (completeOrderItem.digitPrice - completeOrderItem.preOrderPrice) *
      completeOrderItem.quantity;

    setTotalPayment(
      total +
        (isPudo
          ? completeOrderItem.pudoFee * completeOrderItem.quantity
          : completeOrderItem.deliveryGuyFee * completeOrderItem.quantity)
    );
  }, [completeOrderItem, isPudo]);

  const handleCheckout = async (e) => {
    e.preventDefault();

    const myData = [];
    // Merchant details
    myData["merchant_id"] = process.env.NEXT_PUBLIC_PAYFAST_MERCHANT_ID;
    myData["merchant_key"] = process.env.NEXT_PUBLIC_PAYFAST_MERCHANT_KEY;
    myData["return_url"] = process.env.NEXT_PUBLIC_PAYFAST_RETURN_URL;
    myData["cancel_url"] = process.env.NEXT_PUBLIC_PAYFAST_CANCEL_URL;
    myData["notify_url"] = process.env.NEXT_PUBLIC_PAYFAST_NOTIFY_URL;
    // Buyer details
    myData["name_first"] = completeOrder.firstName;
    myData["name_last"] = completeOrder.lastName;
    myData["email_address"] = completeOrder.email;
    // Transaction details
    myData["m_payment_id"] = `${completeOrder.userId}`;
    myData["amount"] = `${totalPayment}`;
    myData["item_name"] = `order-#${completeOrder.id}`;
    myData["custom_str1"] = completeOrderItem.id;
    myData["custom_str2"] = "complete_payment";
    myData["custom_str3"] = completeOrder.id;
    myData["custom_str4"] = `${
      completeOrderItem.unitPrice * completeOrderItem.quantity
    }`;

    // Generate signature
    const myPassphrase = "aVyF3qeYj5JJQAnNJcaYdxVYbhCnfhfh";
    if (process.env.NODE_ENV !== "development") {
      myData["signature"] = generateSignature(myData, myPassphrase);
    }

    // Dynamically create and submit the form
    const form = document.createElement("form");
    form.method = "POST";
    form.action = process.env.NEXT_PUBLIC_PAYFAST_URL;

    for (let key in myData) {
      if (myData.hasOwnProperty(key)) {
        const input = document.createElement("input");
        input.type = "hidden";
        input.name = key;
        input.value = myData[key];
        form.appendChild(input);
      }
    }

    document.body.appendChild(form); // Append form to the DOM
    form.submit(); // Auto-submit the form
    dispatch(completePaymentModalHide());
    router.push("/");
  };

  return (
    <div>
      {/* <!-- Complete Payment Modal --> */}
      <div
        className={
          completePaymentModal ? "modal fade show block" : "modal fade"
        }
      >
        <div className="modal-dialog max-w-2xl min-w-[95%] md:min-w-[40%]">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="buyNowModalLabel">
                Complete Payment
              </h5>
              <button
                type="button"
                className="btn-close"
                onClick={() => dispatch(completePaymentModalHide())}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                  className="fill-jacarta-700 h-6 w-6 dark:fill-white"
                >
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path d="M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z" />
                </svg>
              </button>
            </div>

            {/* <!-- Body --> */}
            <div className="modal-body p-6">
              <div className="mb-2 flex items-center gap-2">
                <span className="font-display text-jacarta-700 text-sm font-semibold dark:text-white grow">
                  Item
                </span>
                <span className="font-display text-jacarta-700 text-sm font-semibold dark:text-white flex-none w-19">
                  Subtotal
                </span>
              </div>
              <span>
                <div>
                  <div className="dark:border-jacarta-600 border-jacarta-100 relative flex items-center border-t border-b py-4 gap-2 flex-wrap">
                    <span className="grow flex gap-2 max-w-[calc(100%-60px)]">
                      <figure className="self-start">
                        <Image
                          src={completeOrderItem.image}
                          alt="avatar 2"
                          width={60}
                          height={60}
                          className="lg:rounded-2lg lg:w-[60px] lg:h-[60px] md:h-[30px] rounded-lg"
                          loading="lazy"
                        />
                      </figure>

                      <div>
                        <a
                          href={`/product/${completeOrderItem.id}`}
                          className="text-accent text-m"
                        >
                          {completeOrderItem.title}
                        </a>
                        {/* <h3 className="font-display text-jacarta-700 mb-1 text-base font-semibold dark:text-white">
                        Lazyone Panda
                      </h3> */}
                        <div className="flex flex-wrap items-center">
                          <span className="dark:text-jacarta-300 text-jacarta-500 mr-1 block text-sm">
                            {completeOrderItem.isFullPayment
                              ? "Full Payment"
                              : "Partial Paid"}
                          </span>
                        </div>
                      </div>
                    </span>

                    <div className="flex-none w-19">
                      <span className="mb-1 flex items-center whitespace-nowrap">
                        <span className="dark:text-jacarta-100 text-sm font-medium tracking-tight">
                          R{" "}
                          {(completeOrderItem.digitPrice -
                            completeOrderItem.preOrderPrice) *
                            completeOrderItem.quantity}{" "}
                        </span>
                      </span>
                    </div>
                  </div>

                  <div className="flex items-center mb-4">
                    <div
                      className="rounded-lg border border-gray-200 bg-gray-50 p-4 ps-4 dark:border-gray-700 dark:bg-gray-800 mr-1"
                      onClick={() => setIsPudo(true)}
                    >
                      <div className="flex items-start">
                        <div className="flex h-5 items-center">
                          <input
                            id="dhl"
                            aria-describedby="dhl-text"
                            type="radio"
                            name="delivery-method"
                            value=""
                            className="h-4 w-4 border-gray-300 bg-white text-primary-600 focus:ring-2 focus:ring-primary-600 dark:border-gray-600 dark:bg-gray-700 dark:ring-offset-gray-800 dark:focus:ring-primary-600"
                            checked={isPudo}
                            onChange={() => setIsPudo(true)}
                          />
                        </div>

                        <div className="ms-4 text-sm">
                          <label
                            for="dhl"
                            className="font-medium leading-none text-gray-900 dark:text-white"
                          >
                            {" "}
                            R
                            {completeOrderItem.pudoFee *
                              completeOrderItem.quantity}{" "}
                            - PUDO{" "}
                          </label>
                          <p
                            id="dhl-text"
                            className="mt-1 text-xs font-normal text-gray-500 dark:text-gray-400"
                          >
                            Pick up Drop off
                          </p>
                        </div>
                      </div>
                    </div>

                    <div
                      className="rounded-lg border border-gray-200 bg-gray-50 p-4 ps-4 dark:border-gray-700 dark:bg-gray-800"
                      onClick={() => setIsPudo(false)}
                    >
                      <div className="flex items-start">
                        <div className="flex h-5 items-center">
                          <input
                            id="fedex"
                            aria-describedby="fedex-text"
                            type="radio"
                            name="delivery-method"
                            value=""
                            className="h-4 w-4 border-gray-300 bg-white text-primary-600 focus:ring-2 focus:ring-primary-600 dark:border-gray-600 dark:bg-gray-700 dark:ring-offset-gray-800 dark:focus:ring-primary-600"
                            checked={!isPudo}
                            onChange={() => setIsPudo(false)}
                          />
                        </div>

                        <div className="ms-4 text-sm">
                          <label
                            for="fedex"
                            className="font-medium leading-none text-gray-900 dark:text-white"
                          >
                            {" "}
                            R
                            {completeOrderItem.deliveryGuyFee *
                              completeOrderItem.quantity}{" "}
                            Courier Guy{" "}
                          </label>
                          <p
                            id="fedex-text"
                            className="mt-1 text-xs font-normal text-gray-500 dark:text-gray-400"
                          >
                            Door to door
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* <!-- Total --> */}
                <div className="dark:border-jacarta-600 border-jacarta-100 mb-2 flex items-center justify-between border-b py-2.5">
                  <span className="font-display text-jacarta-700 hover:text-accent font-semibold dark:text-white">
                    Total
                  </span>
                  <div className="ml-auto">
                    <span className="flex items-center whitespace-nowrap">
                      <span className="text-green font-medium tracking-tight">
                        R {totalPayment.toFixed(2)}
                      </span>
                    </span>
                  </div>
                </div>
              </span>
              {/* <!-- Terms --> */}
              <div
                className={`mt-4 flex items-center space-x-2 ${
                  completeOrder.paymentMethod === "EFT" ? "hidden" : ""
                }`}
              >
                <input
                  type="checkbox"
                  id="buyNowTerms"
                  checked={buyNowTerms}
                  className="checked:bg-accent dark:bg-jacarta-600 text-accent border-jacarta-200 focus:ring-accent/20 dark:border-jacarta-500 h-5 w-5 self-start rounded focus:ring-offset-0"
                  onChange={() => setBuyNowTerms(!buyNowTerms)}
                />
                <label
                  htmlFor="buyNowTerms"
                  className="dark:text-jacarta-200 text-sm"
                >
                  By checking this box, I agree to {"Ixcitement's"}{" "}
                  <Link href="/tarms" className="text-accent">
                    Terms of Service
                  </Link>
                </label>
              </div>
            </div>
            {/* <!-- end body --> */}

            {completeOrder.paymentMethod == !"EFT" ? (
              <div className="modal-footer">
                <div className="flex items-center justify-center space-x-4">
                  <LoadingButton
                    onClick={handleCheckout}
                    disabled={!buyNowTerms}
                  >
                    Check Out
                  </LoadingButton>
                </div>
              </div>
            ) : (
              <div className="min-w-0 flex-1 space-y-8 mx-4 mb-6">
                <div className="space-y-4">
                  <p className="text-sm font-normal text-gray-500 dark:text-gray-400">
                    Complete Payment By Making a bank transfer to the account
                    number information provided below:
                  </p>
                  <p className="text-sm font-normal text-gray-500 dark:text-gray-400 capitalize">
                    Ixcitement Enterprise pty ltd <br /> Bank Name: FNB <br />
                    Bank Code: 250655 <br />
                    Account No: 63127607084 <br /> Reference: your name and
                    surname
                  </p>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompletePaymentModal;
