import { useRouter } from "next/router";
import { useEffect } from "react";
import { getItem } from "./localStorageHelper";

const withAuthAdmin = (WrappedComponent) => {
  const Wrapper = (props) => {
    const router = useRouter();

    const storedUser = getItem("userInfo");
    const storedToken = getItem("token");

    useEffect(() => {
      // If the user is not logged in, redirect to login page
      if (
        !storedUser ||
        !storedToken ||
        !["phiwokuhlegondwe@gmail.com", "ixcitementstore@gmail.com"].includes(
          storedUser.email
        )
      ) {
        router.push("/"); // Redirect to login page
      }
    }, [storedUser, storedToken, router]);

    // If authenticated, render the wrapped component
    return <WrappedComponent {...props} />;
  };

  return Wrapper;
};

export default withAuthAdmin;
